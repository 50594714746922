import React, {useEffect, useState} from 'react';
import {
    Button,
    DatePicker,
    LoadingOverlay,
    TextField,
    Toggle,
    VOBSummary
} from '@finpay-development/shared-components';
import {
    Autocomplete,
    Box,
    Grid,
    InputAdornment,
    MenuItem,
    Paper,
    TextField as MuiTextField,
    Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import {
    configGetPayers,
} from '../../../admin-configuration/state/admin-configuration-thunk';
import {
    ImplementationFacility,
} from '../../../implementation-specialist/components/implementation-clients/details/models/implementation-facility';
import {
    getClientFacilities,
} from '../../../implementation-specialist/state/facility/implementation-facility-thunk';
import {
    ClientStatusCardViewModel,
} from '../../../shared/model/client-status-card';
import {RootState} from '../../../shared/state/root-reducer';
import {AppDispatch} from '../../../shared/state/store';
import {Utils} from '../../../shared/utils';
import {vobPatientSchema} from '../../../shared/validation/schemas';
import {EstimatorBody, PayorLocRateLos, emptyEstimate} from '../../models/estimator';
import {vobPatientPostBody} from '../../models/patient';
import {
    FacilityPayerPlans,
    FacilityPayers,
    Vob,
    VobPostBody,
    emptyVob,
} from '../../models/vob';
import {clearEstimator, clearNewEstimate, setEstimator} from '../../state/estimator-slice';
import {clearPatient, setPatient} from '../../state/vob-patient-slice';
import {saveVobPatient} from '../../state/vob-patient-thunk';
import {
    getEstimatorFacilityPayers,
    getVobClassifications,
    saveVob,
} from '../../state/vob-thunk';
import {clearFacilityPayers, clearPaymentURLParams, clearVOB, setVOB } from '../../state/vob-slice';
import {clearRiskAssessment} from '../../state/risk-assessment-slice';
import {VOBModal} from './vob-modal';
import {PatientSearchArea} from './patient-search-area';
import {admissionsAdvisorUtils} from '../../utils/admission-advisor-utils';
import {patientService} from 'src/patient/services/patient-service';
import { TakePaymentModal } from 'src/shared/components/take-payment-modal';
import { PAYMENT_TYPES } from 'src/patient/components/models/payment-type';
import AlertBoxes from './alert-box';
import { getAlerts, getEmployers } from 'src/meta-data/state/meta-data-thunk';
import { Alert, Employer } from 'src/meta-data/models/metaData';
import { showErrorStatus } from 'src/security/state/user-slice';
import useIsClientConfiguredForNewEstimate from 'src/admissions-advisor/utils/useClientConfigHook';
import {
    CrossoverSelectionEnum,
    Estimate as NewEstimate,
    SummarySelections,
} from '@finpay/estimation-types';
import { callNewEstimatePut } from 'src/admissions-advisor/state/estimator-thunk';
import { setSelectedPatient, setSelectedPatientV2 } from 'src/patient/state/patient-slice';

interface VobProps {
    handleEstimatorTabClick1: (tab: number) => void;
}

function VerificationOfBenefits(props: VobProps) {
    const {handleEstimatorTabClick1} = props;
    const [enablePatientSaveButton, setEnablePatientSaveButton] =
        useState(false);
    const [iocNotManagedStatus, setIOCNotManagedStatus] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [isVobModalOpen, setIsVobModalOpen] = useState(false);
    const [vobUpdateAction, setVobUpdateAction] = useState('');
    const [payersPlanList, setPayerPlanList] = useState<FacilityPayerPlans[]>(
        []
    );
    const [paymentData, setPaymentData] = useState<any>(undefined);
    const [payerAlerts, setPayerAlerts] = useState<Alert[]>([])
    const [employerAlerts, setEmployerAlerts] = useState<Alert[]>([])
    const [groupAlerts, setGroupAlerts] = useState<Alert[]>([])
    const [prefixAlerts, setPrefixAlerts] = useState<Alert[]>([])

    let initInboundPayer: FacilityPayerPlans[] = [
        {
            facilityPayorPlanId: 0,
            facilityPayorId: 0,
            clientFacilityId: 0,
            cfgPayorPlanId: 0,
            facilityPayorPlanCode: '',
            facilityPayorPlanName: '',
            facilityPayorLocRateLos: [],
        },
    ];

    const dispatch = useDispatch<AppDispatch>();
    const paramId = -2;

    const state = {
        clients: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    .filteredByIsActive
        ),
        facilities: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility.facilities
        ),
        isLoadingClients: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient
                    .isLoading
        ),
        isLoadingFacilities: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility.isLoading
        ),
        isLoadingFacilityPayers: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.vobContext?.isLoading
                    ?.isLoadingFacilityLocPayers
        ),
        payers: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.payers
        ),
        vobPatientState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext?.vobPatientContext.patient
        ),
        vob: useSelector(
            (state: RootState) => state.admissionsAdvisorContext?.vobContext.vob
        ),
        estimatorState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.estimator
        ),
        vobClassificationsState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.vobContext?.vobClassifications
        ),
        facilityPayersState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.vobContext?.facilityPayers
        ),
        clientAlerts: useSelector(
            (state: RootState) => {
                return state.metaData.alerts
            }
        ),
        clientEmployers: useSelector(
            (state: RootState) => {
                return state.metaData.employers
            }
        ),
        newEstimate: useSelector(
            (state: RootState) => {
                return state?.admissionsAdvisorContext.estimatorContext.newEstimate
            }
        )
    };

    const isClientConfiguredForNewEstimate = useIsClientConfiguredForNewEstimate();

    const {
        clients,
        facilities,
        isLoadingClients,
        isLoadingFacilities,
        isLoadingFacilityPayers,
        payers,
        vob,
        estimatorState,
        vobPatientState,
        vobClassificationsState,
        facilityPayersState,
        clientAlerts,
        clientEmployers,
        newEstimate,
    } = state;

    const [payerNameInputValue, setPayerNameInputValue] = useState(vob.payer?.payorName || "")
    const [planNameInputValue, setPlanNameInputValue] = useState(vob.plan?.planName || "")

    const patientValidationSchema = Yup.object(vobPatientSchema);

    

    useEffect(() => {
        dispatch(configGetPayers(paramId));
        dispatch(getVobClassifications(paramId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
            if (vob?.payer?.payorId) {
                const payerToSave: FacilityPayers = facilityPayersState?.find(
                    (el: FacilityPayers) => el.cfgPayorId === vob.payer.payorId
                )!;

                if(payerToSave) {
                    const sortedPlans = [...payerToSave.facilityPayorPlans].sort((a, b) =>
                        a.facilityPayorPlanName! > b.facilityPayorPlanName! ? 1 : -1
                    )
    
                    initInboundPayer = Utils.deepClone(sortedPlans);
            
                    setPayerPlanList(sortedPlans);
                }
                forceValidateOnload();
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vob.payer, facilityPayersState]);

    useEffect(() => {
        const handleGetEncounter = async () => {
            const instanceOfCare =
                await patientService.getPatientInstanceOfCare({
                    patientId: vobPatientState?.fpPatientId!,
                    encounterId: vob.patientEncounter?.patientEncounterId!,
                });
            const workFlowStatus =
                instanceOfCare.entity.workflow.workflowStatus
                    .workflowStatusDesc;
            if (workFlowStatus === 'Not Managed') {
                setIOCNotManagedStatus(true);
            }
        };
        if (
            typeof vob.patientEncounter?.patientEncounterId === 'number' &&
            vob.patientEncounter?.patientEncounterId > 0
        ) {
            handleGetEncounter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vob.patientEncounter]);

    useEffect(() => {
        if (!vobPatientState.isLoading && vobUpdateAction === 'selfpay') {
            setVobUpdateAction('');
            doSelfPayVOB();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vobPatientState.isLoading]);

    useEffect(() => {
        if (
            vob?.urlParams !== undefined &&
            vob.client?.clientId &&
            estimatorState.isActive
        ) {
            const selectedClientId = vob.client?.clientId;
            let vobStateCopy = {...vob};

            if (vob?.initialInboundPt) {
                createPatientFormik?.setFieldTouched('plan', false, false);
                createPatientFormik?.setFieldTouched('payer', false, false);
                dispatch(getClientFacilities(+selectedClientId));
                const clientToSave = clients?.find(
                    client => client?.clientId === +selectedClientId
                );
                vobStateCopy.client = {
                    clientId: clientToSave?.clientId,
                    clientName: clientToSave?.clientName,
                };
                vobStateCopy.initialInboundPt = false;
                handleEstimatorTabClick1(1);
            } else {
                initHandleClientChange(selectedClientId);
            }
            dispatch(setVOB(vobStateCopy));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (vob.urlParams && vob.urlParams.inboundPatient !== 'new') {
            setPayerPlanList(initInboundPayer);
        }
        if (vob?.urlParams?.op === 'pay') {
          setPaymentData({
            patientId: vobPatientState?.fpPatientId,
            patientEncounterId: estimatorState?.finPay?.patientEncounterId,
            paymentType: PAYMENT_TYPES.DOWN_PAYMENT,
            paymentChannelId: 3
          });
          setOpenPaymentModal(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  // setting up clientAlerts
  // needs client id from vob and the meta data
  useEffect(() => {
	const clientId = vob?.client?.clientId

	// possible that we are still loading vob
	if(!clientId) return

	const alerts = clientAlerts[clientId]
 
	// if we have a client id and our client's alerts, set everything
	if(clientId && alerts && vob) {

		const payerAlerts = clientAlerts[clientId].payer.filter((payerAlert: Alert) => {
			return payerAlert.payor_id === vob.payer.payorId
		})

		const groupAlerts = clientAlerts[clientId].group.filter((groupAlert: Alert) => {
			return groupAlert?.group_code === vob.groupNum
		})

        const employerAlerts = clientAlerts[clientId].employer.filter((employerAlert) => {
            return employerAlert.client_employer_id === vob.employer?.employerId
        })

        // like all alerts, there may be more than one note for the same prefix
        // also, alert notes shouldn't consolidate over a single letter prefix "M"
        // in the case of "MTN"
        // this gives precedence to the prefixes with the largest character count
        let newPrefixAlerts: Alert[] = []
        const prefixes = clientAlerts[clientId].prefix

        for(let i = 0; i < prefixes.length; i++) {
            if(prefixes.length > 0) {
                const prefixAlert = prefixes[i]
                const subStringEndingIndex = prefixAlert.prefix!.length;

                const policyNumberSubString = vob?.policyNum?.substring(0, subStringEndingIndex)
    
                const isAMatch = policyNumberSubString === prefixAlert.prefix
                // match
                if(isAMatch) {
                    // if nothing in the new alerts, add it
                    if(newPrefixAlerts.length === 0) {
                        newPrefixAlerts.push(prefixAlert)
                        continue;
                    }
                    
                    // length is same as the first prefix
                    if(newPrefixAlerts[0].prefix!.length === prefixAlert.prefix!.length) {
                        // add it the list, don't replace
                        newPrefixAlerts.push(prefixAlert)
                        continue
                    }

                    // length is greater than the first prefix
                    if(newPrefixAlerts[0].prefix!.length < prefixAlert.prefix!.length) {
                        // replace with new alert
                        newPrefixAlerts = [prefixAlert]
                        continue
                    }
                }
            }

        }

		setPayerAlerts(payerAlerts)
        setEmployerAlerts(employerAlerts)
		setGroupAlerts(groupAlerts)
		setPrefixAlerts(newPrefixAlerts)

	} else if(clientId && !clientAlerts[clientId]) {
		// client id but no alerts, fetch the alerts
		dispatch(getAlerts(clientId))
	}
  }, [vob, clientAlerts])

  useEffect(() => {
    const clientId = vob?.client?.clientId

	// possible that we are still loading vob
	if(!clientId) return

	const employers = clientEmployers[clientId]

    if (clientId && !employers) {
        // get employers
        dispatch(getEmployers(clientId))
    }
  }, [vob, clientEmployers])

    function checkIfPatientIsValid(value: any) {
        patientValidationSchema
            .validate(value)
            .then(() => {
                setEnablePatientSaveButton(true);
            })
            .catch(() => {
                setEnablePatientSaveButton(false);
            });
    }

    // formik for create patient
    const createPatientFormik = useFormik({
        initialValues: {
            digitalVerificationMethod: vob?.digitalVerificationMethod,
            client: vob?.client?.clientId,
            facility: vob?.facility?.facilityId,
            firstName: vobPatientState?.firstName || '',
            lastName: vobPatientState?.lastName || '',
            dob: !vobPatientState?.birthDate
                ? ''
                : new Date(vobPatientState?.birthDate)
                      .toISOString()
                      .substring(0, 10),
            payer: vob?.payer?.payorId,
            plan: vob?.plan?.payorPlanId,
            groupNum: vob?.groupNum,
            policyNum: vob?.policyNum,
        },
        validate: checkIfPatientIsValid,
        enableReinitialize: true,
        validationSchema: patientValidationSchema,
        onSubmit: () => {},
    });

    const clearAAState = () => {
        dispatch(clearPatient());
        dispatch(setSelectedPatient({}))
        dispatch(setSelectedPatientV2({}))
        dispatch(clearVOB());
        dispatch(clearEstimator());
        dispatch(clearFacilityPayers());
        dispatch(clearRiskAssessment());
        dispatch(clearNewEstimate())
        setPayerPlanList([]);
        createPatientFormik.setFieldValue('dob', null, false);
        createPatientFormik?.setFieldTouched('plan', false, false);
        createPatientFormik?.setFieldTouched('payer', false, false);
        checkIfPatientIsValid(createPatientFormik);
    };

    const handleClientChange = (e: Event) => {
        const selectedClientId = (e.target as HTMLInputElement).value;

        setPayerPlanList([]);

        createPatientFormik?.setFieldTouched('plan', false, false);
        createPatientFormik?.setFieldTouched('payer', false, false);

        dispatch(getClientFacilities(+selectedClientId));

        // only fetch alerts if we don't have them in redux
        if(!clientAlerts[+selectedClientId]) {
            dispatch(getAlerts(selectedClientId))
        }
        // only fetch employers if we don't have them in redux
        if(!clientEmployers[+selectedClientId]) {
            dispatch(getEmployers(selectedClientId))
        }

        const clientToSave = clients?.find(
            client => client?.clientId === +selectedClientId
        );

        const vobStateCopy = {...vob};
        const estimatorStateCopy = Utils.deepClone(estimatorState);

        vobStateCopy.client = {
            clientId: clientToSave?.clientId,
            clientName: clientToSave?.clientName,
        };

        // // new client, means new facility, which means new payer and plans
        vobStateCopy.payer = emptyVob.payer
        vobStateCopy.plan = emptyVob.plan

        estimatorStateCopy.client = {
            clientId: clientToSave?.clientId,
            clientName: clientToSave?.clientName,
        };

        estimatorStateCopy.selectedLevelsOfCare = [];
        estimatorStateCopy.hasPriorCare = false;
        estimatorStateCopy.hasFinancialAssistance = false;

        estimatorStateCopy.facility = {
            ...emptyEstimate.facility
        };

        dispatch(setVOB(vobStateCopy));
        dispatch(setEstimator(estimatorStateCopy));
    };

    const initHandleClientChange = (id: any) => {
        const selectedClientId = id;

        setPayerPlanList([]);

        createPatientFormik?.setFieldTouched('plan', false, false);
        createPatientFormik?.setFieldTouched('payer', false, false);

        dispatch(getClientFacilities(+selectedClientId));

        // only fetch alerts if we don't have them in redux
        if(!clientAlerts[+selectedClientId]) {
            dispatch(getAlerts(selectedClientId))
        }

        // only fetch employers if we don't have them in redux
        if(!clientEmployers[+selectedClientId]) {
            dispatch(getEmployers(selectedClientId))
        }

        const clientToSave = clients?.find(
            client => client?.clientId === +selectedClientId
        );

        const vobStateCopy = {...vob};

        vobStateCopy.client = {
            clientId: clientToSave?.clientId,
            clientName: clientToSave?.clientName,
        };

        // // new client, means new facility, which means new payer and plans
        vobStateCopy.payer = emptyVob.payer
        vobStateCopy.plan = emptyVob.plan

        dispatch(setVOB(vobStateCopy));
    };

    const handleFacilityChange = async (e: Event) => {
        const selectedFacilityId = (e.target as HTMLInputElement).value;

        setPayerPlanList([]);

        createPatientFormik?.setFieldTouched('plan', false, false);
        createPatientFormik?.setFieldTouched('payer', false, false);

        const { payload } = await getFacilityLocPayers(+selectedFacilityId);
        // update state
        const facilityToSave = facilities
            ?.flat()
            .find(fac => fac?.facilityId === +selectedFacilityId);

        const vobStateCopy = {...vob};

        vobStateCopy.facility = {
            facilityId: facilityToSave?.facilityId,
            facilityName: facilityToSave?.facilityName,
        };

        if(payload) {

            const facilityPayers = [...(payload as FacilityPayers[])].sort((a, b) => a.facilityPayorName! > b.facilityPayorName! ? 1 : -1)

            let payerToSave: FacilityPayers | null
            
            if(vobStateCopy.payer.payorId) {
                payerToSave = admissionsAdvisorUtils.comparePayers(facilityPayers, vobStateCopy)
            } else {
                payerToSave = facilityPayers?.find(
                    (el: FacilityPayers) => el.cfgPayorId === 14
                )!;
            }

    
            // if we didn't find Average Out-of-Network, dont do anything more
            if(!payerToSave) {
                vobStateCopy.payer = {...emptyVob.payer} 
                vobStateCopy.plan = {...emptyVob.plan}

                const estimatorStateCopy = Utils.deepClone(estimatorState);
                
                estimatorStateCopy.selectedLevelsOfCare = [];
                estimatorStateCopy.hasPriorCare = false;
                estimatorStateCopy.hasFinancialAssistance = false;
                    
                estimatorStateCopy.facility = {
                    facilityId: facilityToSave?.facilityId,
                    facilityName: facilityToSave?.facilityName,
                };
    
                dispatch(setVOB(vobStateCopy))
                dispatch(setEstimator(estimatorStateCopy));

                if (isClientConfiguredForNewEstimate && newEstimate?.estimateId && newEstimate?.vobId === vobStateCopy.vobId) {
                    const putEstimateBody: NewEstimate = {
                        description: newEstimate?.description?.substring(newEstimate?.description?.indexOf("- ")),
                        facilityLevelOfCare: [],
                        vobId: vobStateCopy.vobId,
                        advisorPatientId: newEstimate.advisorPatientId,
                        clientId: newEstimate.clientId,
                        facilityId: facilityToSave?.facilityId ?? 0,
                        patientEncounterId: vob.patientEncounter?.patientEncounterId!,
                        quoteMethod: newEstimate.quoteMethod,
                        anticipatedAdmitDate: newEstimate?.anticipatedAdmitDate,
                        isPlanYearCrossover: newEstimate?.isPlanYearCrossover ||  false,
                        isActive: true,
                        crossoverSelection: newEstimate.crossoverSelection ?? CrossoverSelectionEnum.BEFORE,
                        financialAssistance: newEstimate?.financialAssistance,
                        priorCare: newEstimate?.priorCare,
                        summarySelection: newEstimate.summarySelection ?? SummarySelections.NONADJUSTED
                    };

                    await dispatch(saveVob({vobBody: vobStateCopy}));
                    await dispatch(callNewEstimatePut({estimate: putEstimateBody, estimateId: newEstimate?.estimateId}));
                }
                return
            }
    
            const vobPayer = {
                payorId: payerToSave?.cfgPayorId,
                payorName: payerToSave?.facilityPayorName,
                payorCode: payerToSave?.facilityPayorCode,
                inNetwork: payerToSave?.inNetwork,
                payersList: facilityPayers,
            };

            let planToSave: FacilityPayerPlans | null;

            if(vobStateCopy.plan.payorPlanId) {
                planToSave = admissionsAdvisorUtils.comparePlans(payerToSave, vobStateCopy)
            } else {
                planToSave = payerToSave.facilityPayorPlans?.find(
                    (el: FacilityPayerPlans) => el.cfgPayorPlanId === 33
                )!;
            }
    
            let vobPlan
    
            if(!planToSave) {
                // if no plan 'ALL' is found, reset plan
                vobPlan = {...emptyVob.plan}
            } else {
                vobPlan = {
                    payorPlanId: planToSave?.cfgPayorPlanId,
                    planName: planToSave?.facilityPayorPlanName,
                    planCode: planToSave?.facilityPayorPlanCode,
                    facilityPlanId: planToSave?.facilityPayorPlanId,
                    pdrRate: (planToSave?.facilityPayorLocRateLos as PayorLocRateLos)
                        ?.pdrRate,
                    losDays: (planToSave?.facilityPayorLocRateLos as PayorLocRateLos)
                        ?.losDays,
                    payersPlanList: payersPlanList,
                };
            }
    
            vobStateCopy.payer = vobPayer
            vobStateCopy.plan = vobPlan

            const sortedPlans = [...payerToSave.facilityPayorPlans].sort((a, b) =>
                a.facilityPayorPlanName! > b.facilityPayorPlanName! ? 1 : -1
            )

            initInboundPayer = Utils.deepClone(sortedPlans);
        
            setPayerPlanList(sortedPlans);
        }

        const estimatorStateCopy = Utils.deepClone(estimatorState);
        estimatorStateCopy.selectedLevelsOfCare = [];
        estimatorStateCopy.hasPriorCare = false;
        estimatorStateCopy.hasFinancialAssistance = false;
            
        estimatorStateCopy.facility = {
            facilityId: facilityToSave?.facilityId,
            facilityName: facilityToSave?.facilityName,
        };

        dispatch(setVOB(vobStateCopy));
        dispatch(setEstimator(estimatorStateCopy));

        if (isClientConfiguredForNewEstimate && newEstimate?.estimateId && newEstimate?.vobId === vobStateCopy.vobId) {
            const putEstimateBody: NewEstimate = {
                description: newEstimate?.description?.substring(newEstimate?.description?.indexOf("- ")),
                facilityLevelOfCare: [],
                vobId: vobStateCopy.vobId,
                advisorPatientId: newEstimate.advisorPatientId,
                clientId: newEstimate.clientId,
                facilityId: facilityToSave?.facilityId ?? 0,
                patientEncounterId: vob.patientEncounter?.patientEncounterId!,
                quoteMethod: newEstimate.quoteMethod,
                anticipatedAdmitDate: newEstimate?.anticipatedAdmitDate,
                isPlanYearCrossover: newEstimate?.isPlanYearCrossover ||  false,
                isActive: true,
                crossoverSelection: newEstimate.crossoverSelection ?? CrossoverSelectionEnum.BEFORE,
                financialAssistance: newEstimate?.financialAssistance,
                priorCare: newEstimate?.priorCare,
                summarySelection: newEstimate.summarySelection ?? SummarySelections.NONADJUSTED
            };

            await dispatch(saveVob({vobBody: vobStateCopy}));
            await dispatch(callNewEstimatePut({estimate: putEstimateBody, estimateId: newEstimate?.estimateId}));
        }
    };

    const handleEmployerChange = (e: Event) => {
        const selectedEmployerId = Number((e.target as HTMLInputElement).value)

        if(vob.client.clientId && selectedEmployerId) {
            const employer = clientEmployers[vob.client.clientId].find((employer: Employer) => {
                return employer.clientEmployerId === selectedEmployerId
            })
            
            const vobStateCopy = {...vob};
    
            vobStateCopy.employer = {
                employerId: employer?.clientEmployerId!, 
                employerName: employer?.employerName!
            }
            dispatch(setVOB(vobStateCopy))
        }
    };

    const getFacilityLocPayers = async (facilityId: number) => {
        const config: any = {
            paramId: paramId,
            masterListPayers: payers,
            facilityId: facilityId,
        };
        return await dispatch(getEstimatorFacilityPayers(config));
    };

    const handlePatientFirstNameChange = (e: Event) => {
        const firstName = (e.target as HTMLInputElement).value;
        let vobPatientStateCopy = Utils.deepClone(vobPatientState);
        vobPatientStateCopy.firstName = firstName;
        dispatch(setPatient(vobPatientStateCopy));
    };

    const handlePatientLastNameChange = (e: Event) => {
        const patientLastName = (e.target as HTMLInputElement).value;
        let vobPatientStateCopy = Utils.deepClone(vobPatientState);
        vobPatientStateCopy.lastName = patientLastName;
        dispatch(setPatient(vobPatientStateCopy));
    };

    const handlePayerChange = (payerId: string) => {
        const selectedPayer: FacilityPayers = facilityPayersState?.find(
            (el: any) => el.cfgPayorId === +payerId
        )!;

        const selectedPlans =
            selectedPayer?.facilityPayorPlans?.length > 0
                ? (selectedPayer?.facilityPayorPlans as FacilityPayerPlans[])
                : ([] as FacilityPayerPlans[]);

        const sortedPlans = [...selectedPlans].sort((a, b) =>
            a.facilityPayorPlanName! > b.facilityPayorPlanName! ? 1 : -1
        )

        initInboundPayer = Utils.deepClone(sortedPlans);

        setPayerPlanList(sortedPlans);
        
        // update state
        const vobStateCopy = {...vob};
        vobStateCopy.payer = {
            payorId: selectedPayer?.cfgPayorId,
            payorName: selectedPayer?.facilityPayorName,
            payorCode: selectedPayer?.facilityPayorCode,
            inNetwork: selectedPayer?.inNetwork,
            payersList: facilityPayersState,
        };
        vobStateCopy.plan = emptyVob.plan

        dispatch(setVOB(vobStateCopy));
    };

    const handlePlanChange = (planId: string) => {
        const selectedPlan: FacilityPayerPlans = payersPlanList?.find(
            plan => plan.cfgPayorPlanId === +planId
        )!;
        if (selectedPlan) {
            let vobStateCopy = {...vob};
            vobStateCopy.plan = {
                payorPlanId: selectedPlan.cfgPayorPlanId,
                planName: selectedPlan.facilityPayorPlanName,
                planCode: selectedPlan.facilityPayorPlanCode,
                facilityPlanId: selectedPlan.facilityPayorPlanId,
                pdrRate: (
                    selectedPlan.facilityPayorLocRateLos as PayorLocRateLos
                )?.pdrRate,
                losDays: (
                    selectedPlan.facilityPayorLocRateLos as PayorLocRateLos
                )?.losDays,
                payersPlanList: payersPlanList,
            };

            // check for self pay option
            const payerNameLower =
                vobStateCopy?.payer?.payorName?.toLowerCase();
            if (payerNameLower?.replace(/-|\s/g, '') === 'selfpay') {
                setVobUpdateAction('selfpay');
                saveAdvisorPatient();
            } else {
                vobStateCopy.selfPay = false;
            }
            dispatch(setVOB(vobStateCopy));
            clearEstimateLOC();
            forceValidateOnload();
        }
    };

    const doSelfPayVOB = () => {
        let vobStateCopy = Utils.deepClone(vob);
        const selfPayVob: VobPostBody = admissionsAdvisorUtils.createSelfPayVob(
            vobPatientState?.advisorPatientId!,
            vobStateCopy,
            vobClassificationsState[0]
        );
        dispatch(setVOB(selfPayVob));
        dispatch(saveVob(selfPayVob));
        forceValidateOnload();
    };

    const handleGroupNumberChange = (e: Event) => {
        const groupNumber = (e.target as HTMLInputElement).value;
        
        let vobStateCopy = Utils.deepClone(vob);

        vobStateCopy.groupNum = groupNumber;

        dispatch(setVOB(vobStateCopy));
    };

    const handlePolicyNumberChange = (e: Event) => {
        const policyNumber = (e.target as HTMLInputElement).value;

        let vobStateCopy = Utils.deepClone(vob);
        vobStateCopy.policyNum = policyNumber;
        dispatch(setVOB(vobStateCopy));
    };

    const clearEstimateLOC = () => {
        let estimatorStateCopy = Utils.deepClone(estimatorState);
        estimatorStateCopy.selectedLevelsOfCare = [];
        estimatorStateCopy.hasPriorCare = false;
        estimatorStateCopy.hasFinancialAssistance = false;
        dispatch(setEstimator(estimatorStateCopy));
    };

    const updatePatientFormik = (patientState: vobPatientPostBody) => {
        createPatientFormik.setFieldValue('firstName', patientState.firstName);
        createPatientFormik.setFieldValue('lastName', patientState.lastName);
        createPatientFormik.setFieldValue('dob', new Date(patientState?.birthDate).toISOString().substring(0, 10));
        createPatientFormik.setFieldValue('client', patientState.clientId);
        createPatientFormik.setFieldValue('facility', undefined)
        createPatientFormik.setFieldValue('payer', undefined)
        createPatientFormik.setFieldValue('plan', undefined)
    };

    const updateVOBOnPatientSelection = (clientId: number | undefined) => {
        if(clientId) {
            setPayerPlanList([]);

            createPatientFormik?.setFieldTouched('plan', false, false);
            createPatientFormik?.setFieldTouched('payer', false, false);

            dispatch(getClientFacilities(clientId));

            // only fetch alerts if we don't have them in redux
            if(!clientAlerts[clientId]) {
                dispatch(getAlerts(clientId))
            }

            // only fetch employers if we don't have them in redux
            if(!clientEmployers[clientId]) {
                dispatch(getEmployers(clientId))
            }

            const clientToSave = clients?.find(
                client => client?.clientId === clientId
            );

            let vobStateCopy = {...emptyVob};

            vobStateCopy.client = {
                clientId: clientToSave?.clientId,
                clientName: clientToSave?.clientName,
            };

            // // new client, means new facility, which means new payer and plans
            vobStateCopy.payer = emptyVob.payer
            vobStateCopy.plan = emptyVob.plan

            dispatch(setVOB(vobStateCopy));
        }
    }

    const updateVobFormik = (vobBody: Vob) => {
        createPatientFormik.setFieldValue('client', vobBody.client.clientId);
        createPatientFormik.setFieldValue(
            'facility',
            vobBody.facility.facilityId
        );
        createPatientFormik.setFieldValue('payer', vobBody.payer.payorId);
        createPatientFormik.setFieldValue('plan', vobBody.plan.payorPlanId);
    };

    const forceValidateOnload = () => {
        setTimeout(() => {
            createPatientFormik.validateForm(); // unlock the IOC
        }, 500);
    };

    const saveAdvisorPatient = async () => {
        const dobDate: Date = new Date(createPatientFormik.values.dob);
        const patientPayload: vobPatientPostBody = {
            firstName: createPatientFormik.values?.firstName,
            lastName: createPatientFormik.values?.lastName,
            birthDate: dobDate.toISOString(),
            advisorPatientId: vobPatientState?.advisorPatientId,
            fpPatientId: vobPatientState?.fpPatientId,
            clientId: createPatientFormik.values?.client,
        };
        if (vobPatientState.advisorPatientBody) {
            patientPayload.advisorPatientBody =
                vobPatientState.advisorPatientBody;
        }
        await dispatch(saveVobPatient(patientPayload));
    };

    const handleVobModalOpen = async () => {
        await saveAdvisorPatient();
        dispatch(
            setVOB(
                // preemptively populate some of the existing vob data into redux before the rest of vob info is posted
                {
                    ...vob,
                    digitalVerificationMethod:
                        createPatientFormik.values.digitalVerificationMethod,
                    client: {
                        ...vob.client,
                        clientList: clients
                    },
                    facility: {
                        ...vob.facility,
                        facilityList: facilities?.flat(),
                    },
                    groupNum: createPatientFormik.values.groupNum,
                    policyNum: createPatientFormik.values.policyNum,
                }
            )
        );

        setIsVobModalOpen(true);
    };

    async function handleModalSubmit(isEditMode: boolean) {
        setIsVobModalOpen(false);
    }

    function handleModalCancel() {
        setIsVobModalOpen(false);
    }

    function handleEstimatorTabClick2() {
        handleEstimatorTabClick1(1);
    }

    function clickEstimatorTab(tab: number) {
        handleEstimatorTabClick1(tab);
    }

    function handlePaymentModalCancel() {
      setOpenPaymentModal(false);
      dispatch(clearPaymentURLParams());
    }

    const handleDOBOnChange = (date: Date) => {
        try {
            const dateString = date?.toISOString() 

            const vobPatientStateCopy = Utils.deepClone(vobPatientState);
            vobPatientStateCopy.birthDate = dateString || ""
            dispatch(setPatient(vobPatientStateCopy));
    
            createPatientFormik.setFieldValue('dob', date);
        } catch (error: unknown) {
            dispatch(showErrorStatus("Could not handle date input"))
        }

    }

    const currentClientEmployers = clientEmployers[vob?.client?.clientId!] || []

    const isEmployerDropdownDisabled = !currentClientEmployers || currentClientEmployers.length === 0

    const hasAnAlert = payerAlerts.length > 0 || employerAlerts.length > 0 || groupAlerts.length > 0 || prefixAlerts.length > 0

    const isFacilityDropDownDisabled = !vob?.client?.clientId || isLoadingFacilities

    return (
        <>
            {isLoadingClients ? (
                <LoadingOverlay />
            ) : (
                <>
                    <Grid 
                        container
                        direction="row"
                    >
                        <Grid 
                            container
                            direction="column"
                            sm={hasAnAlert ? 8 : 12} md={hasAnAlert ? 8 : 12} lg={hasAnAlert ? 9 : 12}
                        >
                            <Grid item >
                                <Paper>
                                    <Box
                                        style={{
                                            padding: '1.0em',
                                            borderBottom: '1px solid #cccccc',
                                        }}
                                    >
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            direction="row"
                                        >
                                            <Grid
                                                item
                                                justifyContent="center"
                                                direction="column"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                    flexDirection: 'row',
                                                    paddingBottom: '8px',
                                                }}
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                    style={{marginBottom: '0px'}}
                                                >
                                                    Verification of Benefits
                                                </Typography>
                                                <PatientSearchArea
                                                    clearAAState={clearAAState}
                                                    vob={vob}
                                                    updatePatientFormik={
                                                        updatePatientFormik
                                                    }
                                                    updateVOBOnPatientSelection={updateVOBOnPatientSelection}
                                                    updateVobFormik={updateVobFormik}
                                                    handleEstimatorTabClick={
                                                        clickEstimatorTab
                                                    }
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                justifyContent="center"
                                                direction="column"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                <Grid container direction="row">
                                                    <div hidden={true}>
                                                        <Grid
                                                            item
                                                            justifyContent="center"
                                                            direction="column"
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                flexWrap: 'wrap',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h4"
                                                                className="pr-6"
                                                            >
                                                                Select Verification Method
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Toggle
                                                                leftText="Digital"
                                                                rightText="Manual"
                                                                name="digitalVerificationMethod"
                                                                value={
                                                                    vob?.digitalVerificationMethod
                                                                        ? vob.digitalVerificationMethod
                                                                        : createPatientFormik
                                                                            .values
                                                                            .digitalVerificationMethod
                                                                }
                                                                formik={createPatientFormik}
                                                            />
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box style={{margin: '1.0em', paddingBottom: '30px'}}>
                                        <form>
                                            <Grid container spacing={2} direction="row">
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{marginBottom: '22px'}}
                                                    >
                                                        Enter patient information to look up
                                                        insurance benefits
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} direction="row">
                                                <Grid item xs={4}>
                                                    <TextField
                                                        select
                                                        label="Client"
                                                        test-id="vob-patient-client"
                                                        disabled={Boolean(vob?.vobId)}
                                                        name="client"
                                                        className='state-field'
                                                        value={vob?.client?.clientId}
                                                        onChange={(e: Event) => {
                                                            createPatientFormik.handleChange(
                                                                e
                                                            );
                                                            handleClientChange(e);
                                                        }}
                                                        onBlur={
                                                            createPatientFormik.handleBlur
                                                        }
                                                        error={
                                                            createPatientFormik.touched
                                                                .client &&
                                                            createPatientFormik.errors
                                                                .client
                                                        }
                                                    >
                                                        {clients &&
                                                            [...clients]
                                                                ?.sort((a, b) =>
                                                                    a.clientName >
                                                                    b.clientName
                                                                        ? 1
                                                                        : -1
                                                                )
                                                                .map(
                                                                    (
                                                                        client: ClientStatusCardViewModel
                                                                    ) => (
                                                                        <MenuItem
                                                                            key={
                                                                                client.clientId
                                                                            }
                                                                            value={
                                                                                client.clientId
                                                                            }
                                                                            test-id={`vob-patient-client-option-${client.clientId}`}
                                                                        >
                                                                            {
                                                                                client.clientName
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        select
                                                        label="Facility"
                                                        name="facility"
                                                        test-id="vob-patient-facility"
                                                        className='state-field'
                                                        value={vob?.facility?.facilityId}
                                                        disabled={isFacilityDropDownDisabled}
                                                        loading={isLoadingFacilities}
                                                        onChange={(e: Event) => {
                                                            createPatientFormik.handleChange(
                                                                e
                                                            );
                                                            handleFacilityChange(e);
                                                        }}
                                                        onBlur={
                                                            createPatientFormik.handleBlur
                                                        }
                                                        error={
                                                            createPatientFormik.touched
                                                                .facility &&
                                                            createPatientFormik.errors
                                                                .facility
                                                        }
                                                    >
                                                        {facilities
                                                            .flat()
                                                            .map(
                                                                (
                                                                    facility: ImplementationFacility
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            facility.facilityId
                                                                        }
                                                                        value={
                                                                            facility.facilityId
                                                                        }
                                                                        test-id={`vob-patient-client-option-${facility.facilityId}`}
                                                                    >
                                                                        {
                                                                            facility.facilityName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        select
                                                        label="Employer"
                                                        name="employer"
                                                        className='state-field'
                                                        value={vob.employer?.employerId}
                                                        disabled={
                                                            isEmployerDropdownDisabled 
                                                        }
                                                        onChange={(e: Event) => {
                                                            handleEmployerChange(e);
                                                        }}
                                                        onBlur={
                                                            createPatientFormik.handleBlur
                                                        }
                                                        error={
                                                            createPatientFormik.touched
                                                                .facility &&
                                                            createPatientFormik.errors
                                                                .facility
                                                        }
                                                    >
                                                        {currentClientEmployers
                                                            .map((employer: Employer) => (
                                                                    <MenuItem
                                                                        key={
                                                                            employer.clientEmployerId
                                                                        }
                                                                        value={
                                                                            employer.clientEmployerId
                                                                        }
                                                                    >
                                                                        {
                                                                            employer.employerName
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label="First Name"
                                                        type="text"
                                                        value={vobPatientState?.firstName}
                                                        name="firstName"
                                                        test-id="vob-patient-first-name"
                                                        onChange={(e: Event) => {
                                                            handlePatientFirstNameChange(e);
                                                            createPatientFormik.handleChange(
                                                                e
                                                            );
                                                        }}
                                                        onBlur={
                                                            createPatientFormik.handleBlur
                                                        }
                                                        error={
                                                            createPatientFormik.touched
                                                                .firstName &&
                                                            createPatientFormik.errors
                                                                .firstName
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label="Last Name"
                                                        type="text"
                                                        value={vobPatientState?.lastName}
                                                        name="lastName"
                                                        test-id="vob-patient-last-name"
                                                        onChange={(e: Event) => {
                                                            handlePatientLastNameChange(e);
                                                            createPatientFormik.handleChange(
                                                                e
                                                            );
                                                        }}
                                                        onBlur={
                                                            createPatientFormik.handleBlur
                                                        }
                                                        error={
                                                            createPatientFormik.touched
                                                                .lastName &&
                                                            createPatientFormik.errors
                                                                .lastName
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <DatePicker
                                                        data="date-input"
                                                        error={
                                                            createPatientFormik.touched[
                                                                'dob'
                                                            ] &&
                                                            createPatientFormik.errors[
                                                                'dob'
                                                            ]
                                                        }
                                                        label="Date of Birth"
                                                        value={vobPatientState?.birthDate}
                                                        onChange={(date: Date) => {
                                                            handleDOBOnChange(date)
                                                        }}
                                                        minDate={Utils.getMinDate()}
                                                        maxDate={moment()}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        freeSolo={false}
                                                        loading={isLoadingFacilityPayers}
                                                        options={facilityPayersState}
                                                        getOptionLabel={option =>
                                                            option?.facilityPayorName!
                                                        }
                                                        value={facilityPayersState?.find(
                                                                payer => (payer.cfgPayorId === vob?.payer?.payorId)
                                                            ) || null
                                                        }
                                                        test-id="vob-patient-payor"
                                                        onChange={(e, value) => {
                                                            createPatientFormik.handleChange(
                                                                e
                                                            );
                                                            handlePayerChange(
                                                                value
                                                                    ? value!.cfgPayorId?.toString()
                                                                    : ''
                                                            );
                                                        }}
                                                        inputValue={payerNameInputValue}
                                                        onInputChange={(e, value) => {
                                                            setPayerNameInputValue(value)
                                                        }}
                                                        onBlur={e => {
                                                            createPatientFormik.handleBlur(
                                                                e
                                                            );
                                                        }}
                                                        renderInput={params => (
                                                            <MuiTextField
                                                                {...params}
                                                                name="payer"
                                                                onChange={e => {
                                                                    createPatientFormik.handleChange(
                                                                        e
                                                                    );
                                                                }}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            {<SearchIcon />}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                label="Payer"
                                                                placeholder="Search Payers"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        freeSolo={false}
                                                        options={payersPlanList}
                                                        getOptionLabel={option =>
                                                            option?.facilityPayorPlanName!
                                                        }
                                                        value={payersPlanList.find(
                                                            payer => payer.cfgPayorPlanId === vob?.plan?.payorPlanId
                                                        ) || null}
                                                        onChange={(e, value) => {
                                                            createPatientFormik.handleChange(
                                                                e
                                                            );
                                                            handlePlanChange(
                                                                value
                                                                    ? value!.cfgPayorPlanId?.toString()
                                                                    : ''
                                                            );
                                                        }}
                                                        test-id="vob-patient-plan"
                                                        inputValue={planNameInputValue}
                                                        onInputChange={(e, value) => {
                                                            setPlanNameInputValue(value)
                                                        }}
                                                        onBlur={e => {
                                                            createPatientFormik.handleBlur(
                                                                e
                                                            );
                                                        }}
                                                        renderInput={params => (
                                                            <MuiTextField
                                                                name="plan"
                                                                {...params}
                                                                onChange={e => {
                                                                    createPatientFormik.handleChange(
                                                                        e
                                                                    );
                                                                }}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            {<SearchIcon />}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                label="Plan"
                                                                placeholder="Search Plans"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        test-id="finAdvisor-vob-groupNum"
                                                        label="Group Number"
                                                        type="text"
                                                        value={
                                                            vob?.groupNum
                                                                ? vob.groupNum
                                                                : null
                                                        }
                                                        name="groupNum"
                                                        onChange={(e: Event) => {
                                                            handleGroupNumberChange(e);
                                                            createPatientFormik.handleChange(
                                                                e
                                                            );
                                                        }}
                                                        onBlur={
                                                            createPatientFormik.handleBlur
                                                        }
                                                        required={false}
                                                        error={
                                                            createPatientFormik.touched
                                                                .groupNum &&
                                                            createPatientFormik.errors
                                                                .groupNum
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        test-id="finAdvisor-vob-policyNum"
                                                        label="Policy Number"
                                                        type="text"
                                                        value={
                                                            vob?.policyNum
                                                                ? vob.policyNum
                                                                : null
                                                        }
                                                        name="policyNum"
                                                        onChange={(e: Event) => {
                                                            handlePolicyNumberChange(e);
                                                            createPatientFormik.handleChange(
                                                                e
                                                            );
                                                        }}
                                                        onBlur={
                                                            createPatientFormik.handleBlur
                                                        }
                                                        required={false}
                                                        error={
                                                            createPatientFormik.touched
                                                                .policyNum &&
                                                            createPatientFormik.errors
                                                                .policyNum
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={4}
                                                    justifyContent="flex-end"
                                                    style={{textAlign: 'right'}}
                                                    className="mt-5"
                                                >
                                                    <Button
                                                        test-id="finAdvisor-open-vob-modal-button"
                                                        onClick={() => handleVobModalOpen()}
                                                        disabled={
                                                            !enablePatientSaveButton ||
                                                            (typeof vob.patientEncounter
                                                                ?.patientEncounterId ===
                                                                'number' &&
                                                                vob.patientEncounter
                                                                    ?.patientEncounterId >
                                                                    0 &&
                                                                !iocNotManagedStatus)
                                                        }
                                                        loading={vobPatientState?.isLoading}
                                                    >
                                                        {!!vob?.vobId
                                                            ? 'Edit VOB'
                                                            : createPatientFormik.values
                                                                .digitalVerificationMethod
                                                            ? 'Run VOB'
                                                            : 'Add VOB'}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Box>
                                    {isVobModalOpen && (
                                        <VOBModal
                                            open={isVobModalOpen}
                                            isEdit={!!vob?.vobId}
                                            classifications={vobClassificationsState}
                                            handleVOBModalCancel={handleModalCancel}
                                            handleVOBModalSubmit={handleModalSubmit}
                                        />
                                    )}
                                </Paper>
                            </Grid>
                            {vob?.isValid && (
                                <Grid item >
                                <Paper 
                                    style={{
                                        padding: '1.0em',
                                    }}        
                                >
                                    <VOBSummary vob={vob} />
                                    <Grid
                                        container
                                        // style={{padding: '16px 0'}}
                                        // spacing={2} 
                                        // sm={hasAnAlert ? 8 : 12} md={hasAnAlert ? 8 : 12} lg={hasAnAlert ? 9 : 12}
                                        sm={12} md={12} lg={12}
                                    >
                                        <Grid
                                            item
                                            direction="column"
                                            md={12}
                                            sx={{textAlign: 'right'}}
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleEstimatorTabClick2()
                                                }
                                            >
                                                Run PFR Estimator
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                </Grid>
                            )}
                        </Grid>
                        {hasAnAlert && (
                            <Grid 
                                item
                                sm={4}
                                md={4}
                                lg={3}
                                style={{
                                    paddingLeft: '1rem'
                                }}
                            >
                                <Box>
                                    <AlertBoxes alerts={payerAlerts}/>
                                    <AlertBoxes alerts={employerAlerts}/>
                                    <AlertBoxes alerts={groupAlerts}/>
                                    <AlertBoxes alerts={prefixAlerts}/>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    <TakePaymentModal paymentData={paymentData} open={openPaymentModal} handleModalCancel={handlePaymentModalCancel} />
                </>
            )}
        </>
    );
}


export default VerificationOfBenefits;
