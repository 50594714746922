import {
  PatientEncounter,
} from '../../../patient/components/models/patient-encounter';
import {GuestToken} from '../../../security/services/SSO';
import {
  ApplicationStatusState,
  GetError,
} from '../../../security/model/application-status';
import React, {useState} from 'react';
import HttpInterceptor from '../../../shared/Http/http-interceptor';
import {patientService} from '../../../patient/services/patient-service';
import {
  specialistClientService,
} from '../../../implementation-specialist/services/implementation-client-service';
import {Checkbox, FormControlLabel, Grid, Typography} from '@mui/material';
import {formatNumberToUSD} from '../../../shared/calculators';
import {Button} from '@finpay-development/shared-components';
import {
  MobileInstallmentDocModal as InstallmentDocModal,
} from '../../modal/mobile-installment-doc-modal';
import {
  MobilePaymentSPAModal as SPAModal,
} from '../../modal/mobile-payment-spa-modal';
import {AuthorizationDocumentStatus} from './payment-program-enrollment-page';
import {Workflow} from '../../../shared/model/client';
import {
  PatientPaymentProgram,
} from '../../../patient/components/models/patient-payment-program';
import {EnrollmentThankYouView} from './enrollment-thank-you-view';

interface EnrollmentApprovalViewProps {
  patientEncounter: PatientEncounter;
  selectedAuthDocStatus: AuthorizationDocumentStatus | null;
  guestToken: GuestToken | null;
  setMessage: (msg: ApplicationStatusState) => void;
}

export const EnrollmentApprovalView = (props: EnrollmentApprovalViewProps) => {

  const {
    patientEncounter,
    guestToken,
    selectedAuthDocStatus,
    setMessage,
  } = props;

  const [isInstModalOpen, setIsInstModalOpen] = useState<boolean>(false);
  const [isSPAModalOpen, setIsSPAModalOpen] = useState<boolean>(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [isEnrollmentThankYou, setIsEnrollmentThankYou] = useState(false);
  const [clientDetails, setClientDetails] = useState({
    surveyUrl: '',
    clientName: '',
    email: '',
    phoneNumber: '',
  });
  const [isFormProcessing, setIsFormProcessing] = useState<boolean>(false);

  const typeOfDoc = selectedAuthDocStatus?.authorizationDocuments?.typeOfDoc;

  const handleApproveBtnOnClick = async () => {

    HttpInterceptor(false, guestToken!, true);

    const workflow = getWorkflow({
      currentWorkflow: {
        workflowId: patientEncounter?.workflow.workflowId,
        workflowStatusId: patientEncounter?.workflow.workflowStatus.workflowStatusId,
        workflowSubStatusId: patientEncounter?.workflow.workflowSubStatus.workflowSubStatusId,
      },
      paymentProgram: {
        isAch: patientEncounter?.patientPaymentProgram?.[0].isACH,
        isPaidInFull: patientEncounter?.patientPaymentProgram?.[0].isPaidInFull,
      },
    });

    const resolvedPromises = await Promise.all([
      updateAuthDocStatusAndCreateDocument(
          selectedAuthDocStatus?.authorizationDocumentStatusId!),
      //getting survey url path
      specialistClientService.getClient(patientEncounter.clientId),
      ...(workflow ? [
        patientService.saveStatus({
          workFlow: workflow,
          encounterId: patientEncounter?.patientEncounterId,
          patientId: patientEncounter?.patientId,
        })] : []),
    ]);

    let hasErrors = false;

    for (let i = 0; i < resolvedPromises.length; i++) {
      if (resolvedPromises[i].hasErrors) {
        hasErrors = true;
        break;
      }
    }

    if (hasErrors) {
      setMessage(GetError(
          'A problem occurred while approving your payment program. Please reach out to a FinPay administrator.'));
      return;
    }

    const fetchedClientDetail = resolvedPromises[1]?.entity?.fullClient;

    setIsEnrollmentThankYou(true);
    setClientDetails({
      ...clientDetails,
      surveyUrl: fetchedClientDetail?.surveyUrl,
      clientName: fetchedClientDetail?.clientName,
      email: fetchedClientDetail?.primaryContact?.email,
      phoneNumber: fetchedClientDetail?.primaryContact?.primPhoneNum,
    });

  };

  return (
      <Grid
          item
          sx={{
            padding: '1rem 2rem',
            maxWidth: '535px',
            minWidth: '300px',
          }}
      >
        <Grid>
          <Typography
              variant="h3"
              className="pt-2"
              align="center"
              color="primary"
              test-id="enrollment-page-title"
          >
            {`${patientEncounter?.client?.clientName} ${typeOfDoc ===
            'spa' ?
                'Single Payment Agreement' :
                'Payment Program'}`}
          </Typography>
        </Grid>
        {
          isEnrollmentThankYou ? (
              <EnrollmentThankYouView clientDetails={clientDetails}/>
          ) : (
              <>
                <Grid container sx={{margin: '1rem 0'}}>
                  <Grid item xs={8} className="mt-2">
                    <Typography variant="subtitle2">
                      Patient Financial Responsibility (PFR):
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className="mt-2">
                    <Typography
                        variant="subtitle2"
                        align="right"
                        test-id="enrollment-page-pfr-amt"
                    >
                      {formatNumberToUSD(
                          patientEncounter?.pfrAmt)}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                        variant="subtitle2"
                        test-id="enrollment-page-payment-type"
                    >
                      {`${typeOfDoc === 'spa' ?
                          'Payment' :
                          'Down Payment'}:`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                        variant="subtitle2"
                        align="right"
                        test-id="enrollment-page-payment-amt"
                    >
                      {formatNumberToUSD(
                          patientEncounter?.patientPaymentProgram?.[0]?.downPmtAmt)}
                    </Typography>
                  </Grid>
                  {
                      typeOfDoc !== 'spa' && (
                          <>
                            <Grid item xs={8}>
                              <Typography
                                  variant="subtitle2"
                                  test-id="enrollment-page-recurring-payment"
                              >
                                {getRecurringPaymentText(
                                    patientEncounter?.patientPaymentProgram?.[0])}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                  variant="subtitle2"
                                  align="right"
                                  test-id="enrollment-page-recurring-payment-amt"
                              >
                                {formatNumberToUSD(
                                    patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.paymentDueAmt)}
                              </Typography>
                            </Grid>
                          </>
                      )
                  }
                </Grid>
                <Grid item xs={12} sx={{marginBottom: '1rem'}}>
                  <Typography
                      test-id="enrollment-page-instruction"
                  >
                    {`Click below to approve this Payment Authorization and ${typeOfDoc ===
                    'spa' ?
                        'approve this payment' :
                        ' enroll in this payment program'}.`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{marginBottom: '1rem'}}>
                  <Typography
                      test-id="enrollment-page-auth-doc"
                  >
                    Please click here to review the {' '}
                    <Typography
                        test-id="enrollment-page-auth-doc-link"
                        component="span"
                        variant="body1"
                        color="primary"
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          if (typeOfDoc === 'spa') {
                            setIsSPAModalOpen(true);
                          } else {
                            setIsInstModalOpen(true);
                          }
                        }}
                    >
                      {`Payment Authorization`}
                    </Typography>
                    .
                  </Typography>
                </Grid>
                <Grid sx={{marginBottom: '1rem'}}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            test-id="enrollment-page-approve-checkbox"
                            onChange={(e) => {
                              setIsCheckboxChecked(e.target.checked);
                            }}
                            checked={isCheckboxChecked}
                        />}
                      label="I accept the terms of this Payment Authorization"/>
                </Grid>
                <Grid container justifyContent="center">
                  <Button
                      test-id="enrollment-page-approve-btn"
                      spinnerLeftPosition={6}
                      loading={isFormProcessing}
                      disabled={!isCheckboxChecked || isFormProcessing}
                      onClick={async () => {
                        setIsFormProcessing(true);
                        await handleApproveBtnOnClick();
                        setIsFormProcessing(false);
                      }}
                  >
                    Approve
                  </Button>
                </Grid>
                <InstallmentDocModal
                    isOpen={isInstModalOpen}
                    handleModalCancel={() => {
                      setIsInstModalOpen(false);
                    }}/>
                <SPAModal
                    isOpen={isSPAModalOpen}
                    handleModalCancel={() => {
                      setIsSPAModalOpen(false);
                    }}
                />
              </>
          )
        }

      </Grid>
  );

};

const getWorkflow = ({
                       currentWorkflow: {
                         workflowId,
                         workflowStatusId,
                         workflowSubStatusId,
                       },
                       paymentProgram: {isAch, isPaidInFull},
                     }:
                         {
                           currentWorkflow: {
                             workflowId: number,
                             workflowStatusId: number,
                             workflowSubStatusId: number
                           },
                           paymentProgram: {
                             isAch: boolean,
                             isPaidInFull: boolean
                           }
                         }): Omit<Workflow, 'workflowName'> | null => {

  switch (true) {

    case !(
        workflowId === 3 &&                   //converted complete
        workflowStatusId === 12 &&
        workflowSubStatusId === 23
    ) && isAch:
      return {
        'workflowId': 3,
        'workflowStatus': {
          'workflowStatusId': 17,
          'workflowStatusDesc': 'Converted, Pending',
        },
        'workflowSubStatus': {
          'workflowSubStatusId': 34,
          'workflowSubStatusDesc': 'Awaiting ACH Verification',
        },
      };

    case !isAch && isPaidInFull:
      return {
        'workflowId': 3,
        'workflowStatus': {
          'workflowStatusId': 16,
          'workflowStatusDesc': 'Paid',
        },
        'workflowSubStatus': {
          'workflowSubStatusId': 23,
          'workflowSubStatusDesc': 'None',
        },
      };

    case !isAch && !isPaidInFull:
      return {
        'workflowId': 3,
        'workflowStatus': {
          'workflowStatusId': 12,
          'workflowStatusDesc': 'Converted',
        },
        'workflowSubStatus': {
          'workflowSubStatusId': 23,
          'workflowSubStatusDesc': 'None',
        },
      };

    default:
      return null;
  }

};

const getRecurringPaymentText = (patientPaymentProgram: PatientPaymentProgram) => {

  const terms = patientPaymentProgram?.patientPaymentSchedule?.terms;
  let termAndPaymentFreq;

  switch (patientPaymentProgram.patientPaymentSchedule.paymentFreq.toLowerCase()) {
    case 'm' :
      termAndPaymentFreq = `Monthly `;
      break;
    case 'w' :
      termAndPaymentFreq = `Weekly `;
      break;
    default :
      termAndPaymentFreq = '';
  }

  return `${terms} ${termAndPaymentFreq} Payment(s) of:`;
};

const updateAuthDocStatusAndCreateDocument = async (authorizationDocumentStatusId: number) => {

  const authDocResponse = await patientService.updateAuthorizationDocumentStatus(
      {
        dataToSave: {
          documentStatus: 'signed',
        },
        authorizationDocumentStatusId: authorizationDocumentStatusId,
      });

  if (authDocResponse?.hasErrors) {
    return authDocResponse;
  }

  return await patientService.createNoSigHelloSignDoc({
    authorizationDocumentStatusId,
  });

};