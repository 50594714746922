import { client } from "../../shared/model/client"
import { PayorLocRateLos, PfrEstimator } from "../models/estimator";
import { ImplementationFacility } from "../../implementation-specialist/components/implementation-clients/details/models/implementation-facility";

export interface vobClassificationsItem {
	vobClassificationId: number,
	vobClassificationName: string,
	vobClassificationDesc?: string | null | undefined,
	isInpatient?: boolean | null | undefined,
	ormVersion?: number | null | undefined,
	createUserId?: string | null | undefined,
	createDt?: string | null | undefined,
	lastUpdateUserId?: string | null | undefined,
	lastUpdateDt?: string | null | undefined,
	last_update_dt?: string | null | undefined
}

export interface patientNotes {
	noteText: string,
	noteDt: string,
}

export interface vobClassificationGroup {
	id?: number | null | undefined,
	selectedClassification: vobClassificationsItem | null | undefined,
	coPaySelection: string | null | undefined,
	coInsurance: number | null | undefined,
	coPay: number | null | undefined,
	maxDays: number | null | undefined
}

export interface Employer {
	employerId: number;
	employerName: string;
}
export interface Vob {
	isValid?: boolean, // Value determining status of completeness of VOB. Estimator requires true value to display
	selfPay?: boolean,
	digitalVerificationMethod: boolean,
	patientEncounter?: {
		patientEncounterId: number
	}
	client: {
		clientId?: number,
		clientName?: string,
		clientList?:client[] | null | undefined
	},
	facility: {
		facilityId?: number,
		facilityName?: string,
		facilityList?: ImplementationFacility[] | null | undefined
	},
	payer: {
		payorId?: number,
		payorPlanId?: number;
		payorName?: string,
		payorCode?: string,
		inNetwork?: boolean,
		payersList?: FacilityPayers[] | null | undefined
	},
	plan: {
		payorPlanId?: number,
		planName?: string,
		planCode?: string,
		facilityPlanId?: number,
		pdrRate?: number,
		losDays?: number,
		payersPlanList?: FacilityPayerPlans[] | null | undefined
	},
	groupNum: string,
	policyNum: string,
	liveVOB: boolean,
	activePolicy: boolean,
	isCarryover?: boolean,
	isCopayReset?: boolean,
	policyBeginDate: string,
	policyEndDate: string,
	inNetwDeductible?: number,
	inNetwDeductibleRemaining?: number,
	inNetwFamilyDeductible?: number,
	inNetwFamilyDeductibleRemaining?: number,
	inNetwOopIncluded: boolean,
	inNetwOopMax?: number,
	inNetwOopMaxRemaining?: number,
	inNetwFamilyOopMax?: number,
	inNetwFamilyOopMaxRemaining?: number,
	inNetwVobClassifications?: vobClassificationGroup[],
	ooNetwDeductible?: number,
	ooNetwDeductibleRemaining?: number,
	ooNetwFamilyDeductible?: number,
	ooNetwFamilyDeductibleRemaining?: number,
	ooNetwOopIncluded: boolean,
	ooNetwOopMax?: number,
	ooNetwOopMaxRemaining?: number,
	ooNetwFamilyOopMax?: number,
	ooNetwFamilyOopMaxRemaining?: number,
	ooNetwVobClassifications?: vobClassificationGroup[],
	vobId?:number,
	urlParams?: any, // array of parameters built from the Launch route. Handles Payment Modal triggering
	initialInboundPt?: boolean; // value used for additional handling from Launch Route on VOB
	patientNotes?: patientNotes[]; // Notes specifically tied to VOB. 
	employer?: Employer;
}

export const emptyVob: Vob = {
    isValid: false,
    selfPay: false,
    digitalVerificationMethod: false,
    client: {
        clientId: undefined,
        clientName: '',
        clientList: [],
    },
    facility: {
        facilityId: undefined,
        facilityName: '',
        facilityList: [],
    },
    payer: {
        payorId: undefined,
        payorName: '',
        payorCode: '',
        inNetwork: false,
        payersList: [],
    },
    plan: {
        payorPlanId: undefined,
        planName: '',
        planCode: '',
        facilityPlanId: undefined,
        pdrRate: undefined,
        losDays: undefined,
        payersPlanList: [],
    },
    groupNum: '',
    policyNum: '',
    liveVOB: false,
    activePolicy: true,
		isCarryover: false,
    policyBeginDate: '',
    policyEndDate: '',
    inNetwDeductible: undefined,
    inNetwDeductibleRemaining: undefined,
    inNetwFamilyDeductible: undefined,
    inNetwFamilyDeductibleRemaining: undefined,
    inNetwOopIncluded: true,
    inNetwOopMax: undefined,
    inNetwOopMaxRemaining: undefined,
    inNetwFamilyOopMax: undefined,
    inNetwFamilyOopMaxRemaining: undefined,
    inNetwVobClassifications: [],
    ooNetwDeductible: undefined,
    ooNetwDeductibleRemaining: undefined,
    ooNetwFamilyDeductible: undefined,
    ooNetwFamilyDeductibleRemaining: undefined,
    ooNetwOopIncluded: true,
    ooNetwOopMax: undefined,
    ooNetwOopMaxRemaining: undefined,
    ooNetwFamilyOopMax: undefined,
    ooNetwFamilyOopMaxRemaining: undefined,
    ooNetwVobClassifications: [],
    vobId: undefined,
	employer: undefined
};

export interface VobPostBody {
	vobId?: number | undefined,
	advisorPatientId?: number | undefined,
	fpClientId?: number | undefined,
	fpClientFacilityId?: number | undefined,
	vobBody: Vob,
	estimates?: PfrEstimator[],
	createDt?: string | undefined | null
}

// v2/client/facility/{facilityId}}/plan/loc
export interface FacilityPayers {
	facilityPayorId: number,
	clientFacilityId: number,
	cfgPayorId: number,
	facilityPayorCode?: string,
	facilityPayorName?: string,
	inNetwork: boolean,
	facilityPayorPlans: FacilityPayerPlans[]
}

// v2/client/facility/{facilityId}}/plan/loc
export interface FacilityLevelOfCare {
	facilityLevelOfCareId: number,
	clientFacilityId: number,
	cfgLevelOfCareId: number,
	facilityLevelOfCareCode: string,
	facilityLevelOfCareName?: string,
	isCovered?: boolean | undefined,
	isAddOnLos: boolean
}

// v2/client/facility/{facilityId}}/plan/loc
export interface FacilityPayerPlans {
	facilityPayorPlanId: number,
	facilityPayorId: number,
	clientFacilityId: number,
	cfgPayorPlanId: number,
	facilityPayorPlanCode?: string,
	facilityPayorPlanName: string,
	facilityPayorLocRateLos?: PayorLocRateLos | PayorLocRateLos[]
}

// v2/client/facility/{facilityId}}/plan/loc
export interface FacilityLocPayers {
	clientFacilityId?: number,
	facilityName: string,
	facilityLevelsOfCare: FacilityLevelOfCare[],
	facilityPayors: FacilityPayers[]
}