/* eslint-disable react-hooks/exhaustive-deps */
import '../../../../scss/components/_icons.scss';

import {
    Button,
    LoadingOverlay,
    Status,
    TextField,
} from '@finpay-development/shared-components';

import {PendingOutlined} from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import EditIcon from '@mui/icons-material/Edit';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LockIcon from '@mui/icons-material/Lock';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
    Box,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Typography,
} from '@mui/material';

import {Form, Formik} from 'formik';

import Cookies from 'js-cookie';

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';

import Comments
    from '../../../../implementation-specialist/components/implementation-clients/details/comments';
import LockModal
    from '../../../../implementation-specialist/components/locks/lock-modal';
import AccessControl from '../../../../security/components/access-control';
import {RolePageNames} from '../../../../security/model/role-page-names';
import {formatNumberToUSD} from '../../../../shared/calculators';
import CustomizeableAccordion
    from '../../../../shared/components/customizeable-accordion';
import HeaderWithDetails
    from '../../../../shared/components/header-with-details';
import {configSettings} from '../../../../shared/configuration/config-settings';
import {
    commentsPage,
    LoginVerifiedStatus,
    RecentlyViewedTypes,
    StatusColors,
} from '../../../../shared/enums';
import {
    RecentlyViewedUser,
    UsersCookiesList,
} from '../../../../shared/model/recentlyViewed';
import {RootState} from '../../../../shared/state/root-reducer';
import {AppDispatch} from '../../../../shared/state/store';
import {Utils} from '../../../../shared/utils';

import {patientService} from '../../../services/patient-service';
import {paynowService} from '../../../../guest/services/paynow-service';
import {userService} from 'src/admin/services/admin-user-service';
import {
    admissionsAdvisorService,
} from '../../../../admissions-advisor/services/admissions-advisor-services';
import {
    configGetPayers,
} from '../../../../admin-configuration/state/admin-configuration-thunk';
import {EstimateSummaryModal} from '../../../../admissions-advisor/components/pfr-estimator/estimator-summary-modal/estimator-summary-modal';
import {admissionsAdvisorUtils} from 'src/admissions-advisor/utils/admission-advisor-utils';

import {showErrorStatus} from 'src/security/state/user-slice';
import {
    resetPatientRecordLockingContext,
    setInUseModalOpen_overview,
    setIsIOCModalOpen,
    setLockRequestedById,
    setLockStatusReleased_overview,
    setRequestDeniedModalOpen_overview,
} from '../../../state/patient-record-locking-slice';
import {
    denyRequestOk,
    editModalPoll,
    releaseRecordOk,
    requestReleaseOk,
} from '../../../state/patient-record-locking-thunk';
import {
    clearReleaseChargeError,
    initializePatientEstimatorState,
    initializePatientVOBState,
    resetPatientContext,
    setAccountHolderContext,
    setRecentlyViewedPatients,
    setSelectedEncounter,
    setVobAndEstimate
} from '../../../state/patient-slice';
import {
    getDocuments,
    getInstanceOfCare,
    getPatientNotes,
    getPaymentMethods,
    getS3Document,
    getTransactions,
    getVOBAndEstimateForFinpass,
    getVOBAndNewEstimateForFinpass,
    lockIOC,
    setRecentlyViewedPatient,
    updateIOC,
    updateWarmTransfer,
} from '../../../state/patient-thunk';
import {
    getFinancialStatusColor,
    getPatientStatusByStatusId,
    StatusType,
} from '../../misc/patient-status';
import {
    BalanceAdjustment,
    emptyBalanceAdjustment,
} from '../../models/balance-adjustment';
import {LockablePatientRecordSection} from '../../models/lockable-patient-record-section';
import {
    emptyPatientEncounter,
    PatientEncounter,
} from '../../models/patient-encounter';
import {SignatureStatus} from '../../models/signature-tracking';
import {DocumentsAccordionContents} from '../documents/documents-accordion-contents';
import DocumentsStatusAccordion from '../documents/documents-status-accordion';
import InsuranceAccordionContents from '../insurance/insurance-accordion-contents';
import {InsuranceModal} from '../insurance/insurance-modal';
import ChampionAccordionContents from '../patient champion/champion-accordion-contents';
import {ChampionModal} from '../patient champion/patient-champion-modal';
import PaymentProgramAccordionContents from '../payment-programs/payment-program-accordion-contents';
import {PaymentProgramsModal} from '../payment-programs/payment-programs-modal';
import {PaymentProgramsV2Modal} from '../payment-programs-v2/payment-programs-v2-modal';
import BalanceAdjustmentConfirmationModal from '../payment-status/balance-adjustment/balance-adjustment-confirmation-modal';
import {BalanceAdjustmentModal} from '../payment-status/balance-adjustment/balance-adjustment-modal';
import {PaymentStatusAccordionContents} from '../payment-status/payment-status-accordion-contents';
import {TransactionsAccordionContents} from '../transactions/transactions-accordion-contents';
import {InstanceOfCareModal} from './instance-of-care-modal';

import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import AccountStatusAccordian from '../account/account-status-accordian';
import {PrimaryContact} from 'src/shared/model/primary-contact';
import {ClientStatusCardViewModel} from 'src/shared/model/client-status-card';
import {PatientChampion} from '../../models/patient-champion';
import _ from 'lodash/fp';
import {Transaction} from '../../models/transaction';
import {
    vobClassificationsItem,
    FacilityPayers, emptyVob,
} from 'src/admissions-advisor/models/vob';
import {VerificationOfBenefitsAccordionContents} from '../vob/vob-accordion-contents';
import {FinpassVOBModal} from '../vob/finpass-vob-modal';
import {
    LevelsOfCareAccordionContents
} from '../levels-of-care/loc-accordion-contents';
import {FinpassLevelOfCareModal} from '../levels-of-care/finpass-loc-modal';
import {
    getClientFacilities,
} from '../../../../implementation-specialist/state/facility/implementation-facility-thunk';
import {emptyEstimate} from '../../../../admissions-advisor/models/estimator';

let editModalPollingInterval: any; // an interval assigned via setInterval

interface InstanceOfCareProps {
    doesSelectedPatientEncounterExist: boolean;
    doesInsuranceExist: boolean;
    doesChampionExist: boolean;
    doesPaymentProgramExist: boolean;
    doesDownPaymentExist: boolean;
    doesRecurringPaymentExist: boolean;
    shouldReFetchTransactions: boolean;
    shouldReFetchPaymentMethods: boolean;
    isLoadingTransactions: boolean;
    isLoadingPaymentMethods: boolean;
    handleDisableConvert: (value: string) => void;
}

const InstanceOfCare = (props: InstanceOfCareProps) => {
    const {
        doesSelectedPatientEncounterExist,
        doesInsuranceExist,
        doesChampionExist,
        doesPaymentProgramExist,
        doesDownPaymentExist,
        shouldReFetchPaymentMethods,
        shouldReFetchTransactions,
        doesRecurringPaymentExist,
        isLoadingTransactions,
        isLoadingPaymentMethods,
        handleDisableConvert,
    } = props;

    const compareFunction = (prevValue: any, nextValue: any) =>
        _.isEqual(prevValue, nextValue);

    const stateFields = {
        selectedPatient: useSelector(
            (state: RootState) => state.patientContext.selectedPatient
        ),
        patientEncounters: useSelector(
            (state: RootState) =>
                state.patientContext.selectedPatient?.patientEncounters
        ),
        selectedPatientEncounter: useSelector(
            (state: RootState) => state.patientContext.selectedEncounter,
            compareFunction
        ),
        selectedPatientChampion: useSelector(
            (state: RootState) =>
                state.patientContext.selectedEncounter?.patientChampion,
            compareFunction
        ),
        isEstimateFromFinAdvisor: useSelector(
            (state: RootState) => {
                const totalEstimatePfr = state.patientContext.selectedEstimate.estimator.totalEstimatedPfr
                return Boolean(totalEstimatePfr !== undefined && totalEstimatePfr >= 0)
            }
          ),
        userName: useSelector(
            (state: RootState) => state.userContext.userProfile.userName
        ),
        isLoadingEncounter: useSelector(
            (state: RootState) => state.patientContext.isLoading.patientIOC
        ),
        isPreConverted: useSelector(
            (state: RootState) => state.patientContext.isPreConverted
        ),
        lockingModalOpenStatus: useSelector(
            (state: RootState) =>
                state.patientRecordLockingContext.lockingModalOpenStatus
        ),
        patientModalOpenStatus: useSelector(
            (state: RootState) =>
                state.patientRecordLockingContext.patientModalOpenStatus
        ),
        isLockStatusReleased_overview: useSelector(
            (state: RootState) =>
                state.patientRecordLockingContext.isLockStatusReleased_overview
        ),
        lockRequestedById: useSelector(
            (state: RootState) =>
                state.patientRecordLockingContext.lockRequestedById
        ),
        patientS3Documents: useSelector(
            (state: RootState) => state.patientContext.patientS3Documents
        ),
        preconvertedPatients: useSelector(
            (state: RootState) => state.patientContext.preconvertedPatients
        ),
        convertedPatients: useSelector(
            (state: RootState) => state.patientContext.convertedPatients
        ),
        isIOCModalOpen: useSelector(
            (state: RootState) =>
                state.patientRecordLockingContext.patientModalOpenStatus
                    .isIOCModalOpen
        ),
        missingAddressError: useSelector(
            (state: RootState) => state.patientContext.isError.missingAddress
        ),
        authorizationDocumentStatus: useSelector(
            (state: RootState) =>
                state.patientContext.selectedEncounter
                    ?.authorizationDocumentStatus
        ),
        accountHolderContext: useSelector(
            (state: RootState) => state.patientContext?.accountHolderContext
        ),

        isReleaseChargeError: useSelector(
            (state: RootState) => state.patientContext.isError.releaseCharge
        ),
        releasePaymentStatusMsg: useSelector(
            (state: RootState) => state.patientContext.releasePaymentStatusMsg
        ),
        allClients: useSelector(
            (state: RootState) =>
                state.implementationContext?.implementationSpecialistClient
                    ?.allClients
        ),
        vob: useSelector(
            (state: RootState) => state.patientContext.selectedVOB
        ),
        payers: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.payers
        ),
        estimate: useSelector(
            (state: RootState) => state.patientContext.selectedEstimate.estimator
        ),
        facilities: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility.facilities
        ),
        newEstimate: useSelector(
            (state: RootState) =>  state.admissionsAdvisorContext.estimatorContext.newEstimate
        ),
    };

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const countdownMax = configSettings.REACT_APP_patient_locking_countdown_max;
    const lockPollingInterval =
        configSettings.REACT_APP_patient_locking_polling_interval;

    const {
        selectedPatient,
        patientEncounters,
        selectedPatientEncounter,
        isEstimateFromFinAdvisor,
        userName,
        isLoadingEncounter,
        lockingModalOpenStatus,
        patientModalOpenStatus,
        isLockStatusReleased_overview,
        lockRequestedById,
        patientS3Documents,
        isIOCModalOpen,
        missingAddressError,
        authorizationDocumentStatus,
        accountHolderContext,
        selectedPatientChampion,
        isReleaseChargeError,
        releasePaymentStatusMsg,
        allClients,
        vob,
        payers,
        estimate,
        facilities,
        newEstimate
    } = stateFields;
 
    const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false);
    const [isChampionModalOpen, setIsChampionModalOpen] = useState(false);
    const [isVobModalOpen, setIsVobModalOpen] = useState(false);
    const [isLocModalOpen, setIsLocModalOpen] = useState(false);
    const [estimateFeatureFlag, setEstimateFeatureFlag] = useState(false);
    const [vobClassification, setVOBClassification] = useState<
        vobClassificationsItem[] | any[]
    >([]);
    const [isPaymentProgramsModalOpen, setIsPaymentProgramsModalOpen] =
        useState(false);
    const [isPaymentProgramsV2ModalOpen, setIsPaymentProgramsV2ModalOpen] =
        useState(false);
    const [isBalanceAdjustmentModalOpen, setIsBalanceAdjustmentModalOpen] =
        useState(false);
    const [
        isBalanceAdjustmentConfirmModalOpen,
        setIsBalanceAdjustmentConfirmModalOpen,
    ] = useState(false);
    const [balanceAdjustmentModalValues, setBalanceAdjustmentModalValues] =
        useState<BalanceAdjustment>(emptyBalanceAdjustment);
    const [isIOCModalEditMode, setIsIOCModalEditMode] = useState(false);
    const [isEstimatorSummaryOpen, setEstimatorSummaryOpen] = useState(false);
    const [isRequestReleaseButtonDisabled, setIsRequestReleaseButtonDisabled] =
        useState(false);
    const [isWarmTransferCompleted, setIsWarmTransferCompleted] =
        useState<string>('-1');
    const [isLoadingVOB, setIsLoadingVOB] = useState(false);
    const [facilityPayersState, setFacilityPayersState] =
        useState<FacilityPayers[]>();
    const isTransactionsInit = useRef(false);

    const hasRunOnce = useRef(false);

    // we assume if there is a vob id, there is a vob to work on
    const doesVobExist = Boolean(vob.vobId);

    const doesLocExist = Array.isArray(estimate?.selectedLevelsOfCare) && estimate?.selectedLevelsOfCare.length > 0 && estimate?.selectedLevelsOfCareFacilityType;

    useEffect(() => {
        dispatch(configGetPayers(-2));
    }, [selectedPatientEncounter.facilityId]);

    useEffect(() => {
        if (doesVobExist) setIsLoadingVOB(false);
    }, [doesVobExist]);

    const doPFRAdjustmentsExist =
        selectedPatientEncounter?.doPFRAdjustmentsExist;

    const client: ClientStatusCardViewModel | undefined = allClients.find(
        (c: ClientStatusCardViewModel) =>
            c.clientId === selectedPatientEncounter?.clientId
    );

    let isPaymentProgramV2: boolean = false;
    if (!!client) {
        const {paymentProgramVersion} = client;
        isPaymentProgramV2 = paymentProgramVersion === 2;
    }

    const releasePatientLock = () => {
        if (selectedPatientEncounter?.patientEncounterId) {
            setTimeout(async () => {
                await dispatch(lockIOC({lock: false})); // unlock the IOC
            }, 500);
        }
    };

    const handleIOCModalCancel = () => {
        releasePatientLock();
        dispatch(setIsIOCModalOpen(false));
        clearInterval(editModalPollingInterval);
    };

    const handleIOCModalSubmit = () => {
        dispatch(setIsIOCModalOpen(false));
        clearInterval(editModalPollingInterval);
    };

    const handleInsuranceModalClose = () => {
        setIsInsuranceModalOpen(false);
    };

    const handleChampionModalClose = () => {
        setIsChampionModalOpen(false);
    };

    const handlePaymentProgramsModalClose = () => {
        setIsPaymentProgramsModalOpen(false);
    };

    const handlePaymentProgramsV2ModalClose = () => {
        setIsPaymentProgramsV2ModalOpen(false);
    };

    const handleBalanceAdjustmentModalClose = () => {
        setBalanceAdjustmentModalValues(emptyBalanceAdjustment);
        setIsBalanceAdjustmentModalOpen(false);
    };

    const handleEstimatorSummaryModalClose = () => {
        setEstimatorSummaryOpen(false);
    };

    const handleBalanceAdjustmentModalSave = (data: BalanceAdjustment) => {
        setBalanceAdjustmentModalValues(data);
        setIsBalanceAdjustmentModalOpen(false);
        setIsBalanceAdjustmentConfirmModalOpen(true);
    };

    const handleConfirmBalanceAdjustment = () => {
        setBalanceAdjustmentModalValues(emptyBalanceAdjustment);
        setIsBalanceAdjustmentConfirmModalOpen(false);
    };

    const handleChangeBalanceAdjustment = () => {
        setIsBalanceAdjustmentConfirmModalOpen(false);
        setIsBalanceAdjustmentModalOpen(true);
    };

    const insuranceAccordionModalCallback = () => {
        setIsInsuranceModalOpen(true);
    };

    const championAccordionModalCallback = () => {
        setIsChampionModalOpen(true);
    };

    const paymentProgramsModalCallback = () => {
        if (isPaymentProgramV2) setIsPaymentProgramsV2ModalOpen(true);
        else setIsPaymentProgramsModalOpen(true);
    };

    const balanceAdjustmentModalCallback = () => {
        setIsBalanceAdjustmentModalOpen(true);
    };

    const handleVOBModalCancel = () => {
        setIsVobModalOpen(false);
    };

    function handleLocModalClose() {
        setIsLocModalOpen(false);
    }

    const handleDocumentAccordionExpand = () => {
        if (doesSelectedPatientEncounterExist) {
            dispatch(
                getDocuments({
                    patientId: selectedPatientEncounter?.patientId,
                    encounterId: selectedPatientEncounter?.patientEncounterId,
                })
            );
            dispatch(
                getS3Document({
                    patientId: selectedPatientEncounter?.patientId,
                    encounterId: selectedPatientEncounter?.patientEncounterId,
                })
            );
        }
    };

    const handleTransactionsAccordionExpand = () => {
        if (
            doesPaymentProgramExist &&
            shouldReFetchTransactions &&
            !isLoadingTransactions &&
            selectedPatientEncounter?.patientEncounterId !== 0 &&
            !missingAddressError
        ) {
            dispatch(
                getTransactions({
                    patientId: selectedPatientEncounter?.patientId,
                    encounterId: selectedPatientEncounter?.patientEncounterId,
                    supressErrors: true,
                })
            );
        }
    };

    const handlePaymentProgramAccordionExpand = () => {
        if (
            doesPaymentProgramExist &&
            shouldReFetchPaymentMethods &&
            !isLoadingPaymentMethods &&
            !missingAddressError
        ) {
            dispatch(
                getPaymentMethods({
                    patientId: selectedPatientEncounter?.patientId,
                    encounterId: selectedPatientEncounter?.patientEncounterId,
                    supressErrors: true,
                })
            );
        }
    };

    const handleOpenIOCModal = async (isEdit: boolean) => {
        dispatch(setIsIOCModalOpen(true));
        setIsIOCModalEditMode(isEdit);
    };

    const handleNewEstimateView = (estimateId: number) => {
        window.open(
            `/estimate/${estimateId}`,
            '_blank'
        );
    };

    const handleIOCLocking = async () => {
        const patientId = selectedPatientEncounter.patientId;
        const encounterId = selectedPatientEncounter.patientEncounterId;
        const freshIOC = await patientService.getPatientInstanceOfCare({
            patientId,
            encounterId,
        });
        const IOC = freshIOC.entity;
        const isLocked = IOC?.isLocked || false; // Needed to add the default value to avoid undefined for brand new patient

        if (
            isLocked &&
            IOC?.lockedByUserId !== userName &&
            IOC?.lockedByUserId !== null &&
            IOC?.lockedByUserId?.toLowerCase() !== 'undefined'
        ) {
            // extra checks in case of bad data
            setIsRequestReleaseButtonDisabled(false);
            dispatch(setLockRequestedById(IOC.lockedByUserId));
            dispatch(setInUseModalOpen_overview(true));
        } else {
            // patient not locked by anyone. Go ahead and lock it for me so others are locked out
            dispatch(setLockRequestedById(undefined));

            await dispatch(lockIOC({lock: true, username: userName}));

            doModalPolling(patientId, encounterId);
        }
    };

    useEffect(() => {
        if (
            isIOCModalOpen &&
            selectedPatientEncounter?.patientId &&
            selectedPatientEncounter?.patientEncounterId
        ) {
            handleIOCLocking(); // when ioc modal is open, handle locking logic. both add/edit cases. Only when patientId and encounterId are truthy.
        }
    }, [isIOCModalOpen]);

    useEffect(() => {
        const getVOBClassification = async () => {
            const response =
                await admissionsAdvisorService.getVobClassifications(-2);
            if (response.hasErrors) {
                setVOBClassification([]);
            }
            setVOBClassification(response.entity);
        };
        
        getVOBClassification();
    }, []);

    useEffect(() => {
        const fetchFacilityPayors = async () => {
            const response =
                await admissionsAdvisorService.getEstimatorFacilityLocPayers(
                    -2,
                    selectedPatientEncounter.facilityId
                );

            let responseBody = {...response.entity};

            responseBody.masterListPayers = payers;

            const cleanedData: FacilityPayers[] =
                admissionsAdvisorUtils.fillClientPayerDataGaps(responseBody);
            setFacilityPayersState(cleanedData);
        };

        if (payers && payers.length > 0) {
            fetchFacilityPayors();
        }
    }, [payers, selectedPatientEncounter.facilityId]);

    useEffect(() => {
        const handleReleaseError = async () => {
            const paramId = -2;
            const paymentStatus = {
                paymentStatus: 'Failed',
                paymentId: releasePaymentStatusMsg.paymentId,
            };
            await paynowService.updatePaymentDetails(paramId, paymentStatus);

            let clonedEncounter: PatientEncounter = Utils.deepClone(
                selectedPatientEncounter
            );
            const transactionIndex =
                clonedEncounter.patientTransactions.findIndex(
                    (transaction: Transaction) =>
                        transaction.payment.paymentId ===
                        releasePaymentStatusMsg.paymentId
                );
            clonedEncounter.patientTransactions[
                transactionIndex
            ].payment.isCaptured = true;
            clonedEncounter.patientTransactions[
                transactionIndex
            ].payment.paymentStatus = 'Failed';
            clonedEncounter.patientTransactions[
                transactionIndex
            ].payment.paymentStatusFailed = 'Failed';
            dispatch(setSelectedEncounter(clonedEncounter));
            dispatch(clearReleaseChargeError());
        };

        if (
            isReleaseChargeError &&
            releasePaymentStatusMsg.encounterId ===
                selectedPatientEncounter?.patientEncounterId
        ) {
            const stripeRefundStatus =
                releasePaymentStatusMsg.errorMsg?.includes(
                    'charge_already_refunded'
                );
            if (stripeRefundStatus) {
                handleReleaseError();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReleaseChargeError]);

    const doModalPolling = useCallback(
        async (patientId: number, encounterId: number) => {
            editModalPollingInterval = setInterval(async () => {
                dispatch(
                    editModalPoll({
                        patientId,
                        encounterId,
                        userName,
                        lockablePatientRecordSection:
                            LockablePatientRecordSection.overview,
                    })
                );
            }, lockPollingInterval);
        },
        [dispatch, userName]
    );

    const handleRequestReleaseOk = async () => {
        setIsRequestReleaseButtonDisabled(true);
        dispatch(
            requestReleaseOk({
                userName: userName,
                lockablePatientRecordSection:
                    LockablePatientRecordSection.overview,
            })
        );
    };

    const handleRequestReleaseCancel = () => {
        resetRedux();
        dispatch(setInUseModalOpen_overview(false));
        navigate('/specialist/dashboard');
    };

    const handleReleaseRecordOk = async () => {
        dispatch(
            releaseRecordOk({
                lockablePatientRecordSection:
                    LockablePatientRecordSection.overview,
            })
        );
        dispatch(setIsIOCModalOpen(false));
        clearInterval(editModalPollingInterval);
    };

    const handleRequestDeniedClose = () => {
        resetRedux();
        navigate('/specialist/dashboard');
        dispatch(setRequestDeniedModalOpen_overview(false));
    };

    const resetRedux = () => {
        dispatch(resetPatientContext());
        dispatch(resetPatientRecordLockingContext());
    };

    const handleShowEstimateModal = async () => {
        setEstimatorSummaryOpen(true);
    };

    const handleWarmTransfer = (event: {target: {value: string}}) => {
        setIsWarmTransferCompleted(event.target.value as string);
        handleDisableConvert(event.target.value);
        dispatch(
            updateWarmTransfer({
                isWarmTransferCompleted:
                    event.target.value === 'Yes'
                        ? true
                        : event.target.value === 'No'
                        ? false
                        : null,
            })
        );
    };

    const formRef: any = useRef();

    useEffect(() => {
        if (selectedPatientEncounter?.isWarmTransferCompleted === true) {
            setIsWarmTransferCompleted('Yes');
            handleDisableConvert('Yes');
        }
        if (selectedPatientEncounter?.isWarmTransferCompleted === false) {
            setIsWarmTransferCompleted('No');
            handleDisableConvert('No');
        }
        if (selectedPatientEncounter?.isWarmTransferCompleted === null) {
            setIsWarmTransferCompleted('-1');
            handleDisableConvert('-1');
        }
    }, [selectedPatientEncounter]);

    useEffect(() => {
        const patientId = selectedPatientEncounter?.patientId;
        const encounterId = selectedPatientEncounter?.patientEncounterId;

        if (patientId && encounterId) {
            dispatch(
                getPatientNotes({
                    patientId: selectedPatientEncounter.patientId,
                    encounterId: selectedPatientEncounter.patientEncounterId,
                })
            );
        }
    }, [
        dispatch,
        selectedPatientEncounter?.patientId,
        selectedPatientEncounter?.patientEncounterId,
    ]);

    useEffect(() => {
        const patientId = selectedPatientEncounter?.patientId;
        const encounterId = selectedPatientEncounter?.patientEncounterId;
        if (
            selectedPatientEncounter &&
            selectedPatientEncounter !== emptyPatientEncounter &&
            patientId &&
            encounterId &&
            encounterId !== 0 &&
            selectedPatient?.patientEncounters?.length > 0
        ) {
            dispatch(
                getInstanceOfCare({
                    patientId: patientId,
                    encounterId: encounterId,
                })
            );
            if (
                patientId !== 0 &&
                encounterId !== 0 &&
                doesPaymentProgramExist
            ) {
                handlePaymentProgramAccordionExpand(); // fetch Transactions and Payment Methods as they are needed for Convert validations.
            }
        }
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        dispatch(initializePatientEstimatorState())
        dispatch(initializePatientVOBState());

        if (!!selectedPatientEncounter?.pfrEstimateId) {
            const clientItem: ClientStatusCardViewModel = allClients?.find((client:ClientStatusCardViewModel) =>  client.clientId === selectedPatientEncounter.clientId)!
            const newEstimateClient = clientItem?.convRunNum === 76;
            // checking estimate feature flag
            setIsLoadingVOB(true);
            if (newEstimateClient) {
                setEstimateFeatureFlag(true)
                dispatch(
                    getVOBAndNewEstimateForFinpass({
                        estimateId: selectedPatientEncounter?.pfrEstimateId!,
                    })
                );
            } else {
                dispatch(
                    getVOBAndEstimateForFinpass({
                        estimateId: selectedPatientEncounter?.pfrEstimateId!,
                    })
                );   
            }
        }else if (selectedPatientEncounter.patientEncounterId && !selectedPatientEncounter?.pfrEstimateId && selectedPatientEncounter?.payorRisk?.payorRiskId === 3){
            dispatch(setVobAndEstimate({
                estimate: {
                    ...emptyEstimate,
                    client: {
                        clientId: selectedPatientEncounter.clientId,
                        clientName:
                        selectedPatientEncounter.clientName,
                    },
                    facility: {
                        facilityId:
                        selectedPatientEncounter.facilityId,
                        facilityName:
                        selectedPatientEncounter.facilityName,
                    },
                    //Defaulting quote method to "average length of stay" for estimates created from Finpass
                    quoteMethod:"avglos",
                    finPay: {
                        patientId:
                        selectedPatientEncounter.patientId,
                        patientEncounterId:
                        selectedPatientEncounter
                            .patientEncounterId,
                    },
                },
                vob: {
                    ...emptyVob,
                    client: {
                        clientId: selectedPatientEncounter.clientId,
                        clientName: selectedPatientEncounter.clientName,
                        clientList: [],
                    },
                    facility: {
                        facilityId: selectedPatientEncounter.facilityId,
                        facilityName: selectedPatientEncounter.facilityName,
                        facilityList: [],
                    },

                },
            }))
        }
    }, [selectedPatientEncounter?.pfrEstimateId]);

    useEffect(() => {
        async function getRecentlyViewedData() {
            const recentlyViewedListFromCookie: UsersCookiesList =
                Cookies.getJSON('recentlyViewed');
            if (recentlyViewedListFromCookie) {
                const {usersCookiesList} = recentlyViewedListFromCookie;
                const index = usersCookiesList?.findIndex(
                    (recentlyViewed: RecentlyViewedUser) =>
                        recentlyViewed.userName === userName
                );
                if (index >= 0) {
                    await dispatch(
                        setRecentlyViewedPatients({
                            recentlyViewed:
                                usersCookiesList[index].preConvertedPatients,
                            type: RecentlyViewedTypes.preConvertedPatients,
                        })
                    );
                }
                if (index >= 0) {
                    await dispatch(
                        setRecentlyViewedPatients({
                            recentlyViewed:
                                usersCookiesList[index].convertedPatients,
                            type: RecentlyViewedTypes.convertedPatients,
                        })
                    );
                }
            }

            if (selectedPatientEncounter) {
                const {isConverted, patientId, patientEncounterId} =
                    selectedPatientEncounter;
                if (isConverted) {
                    await dispatch(
                        setRecentlyViewedPatient({
                            patientId,
                            encounterId: patientEncounterId,
                            type: RecentlyViewedTypes.convertedPatients,
                        })
                    );
                } else {
                    await dispatch(
                        setRecentlyViewedPatient({
                            patientId,
                            encounterId: patientEncounterId,
                            type: RecentlyViewedTypes.preConvertedPatients,
                        })
                    );
                }
            }
        }

        getRecentlyViewedData();
    }, [dispatch, selectedPatientEncounter]);

    useEffect(() => {
        if (isLockStatusReleased_overview) {
            doModalPolling(
                selectedPatientEncounter?.patientId,
                selectedPatientEncounter?.patientEncounterId
            );
            dispatch(setLockStatusReleased_overview(false));
        }
    }, [
        doModalPolling,
        isLockStatusReleased_overview,
        selectedPatientEncounter?.patientId,
        selectedPatientEncounter?.patientEncounterId,
    ]);

    const fetchPaymentInfo = async () => {
        if (!isLoadingPaymentMethods && !missingAddressError) {
            await dispatch(
                getPaymentMethods({
                    patientId: selectedPatientEncounter?.patientId,
                    encounterId: selectedPatientEncounter?.patientEncounterId,
                    supressErrors: true,
                })
            );
        }
        if (!isLoadingTransactions && !missingAddressError) {
            await dispatch(
                getTransactions({
                    patientId: selectedPatientEncounter?.patientId,
                    encounterId: selectedPatientEncounter?.patientEncounterId,
                    supressErrors: true,
                })
            );
        }
    };

    useEffect(() => {
        if (
            selectedPatientEncounter?.patientEncounterId &&
            !isLoadingEncounter &&
            !isTransactionsInit.current
        ) {
            isTransactionsInit.current = true;
            fetchPaymentInfo();
        }
    }, []);

    const asyncFetchPatientEncounter = (
        patientId: number,
        encounterId: number,
        isPreConverted: boolean
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            // Assuming getInstanceOfCare is an asynchronous action
            dispatch(
                getInstanceOfCare({
                    patientId: patientId,
                    encounterId: encounterId,
                })
            ).then(
                (result: any) => {
                    // You can resolve with a meaningful value if needed
                    resolve(result);
                },
                error => {
                    // You can reject with the error if needed
                    reject(error);
                }
            );
        });
    };

    const setInitialAccountHolderContext = async (
        contactInfo: PrimaryContact,
        isPatient: boolean
    ) => {
        const accountHolderContext = {
            userName: contactInfo.email,
            isPatient,
            firstName: contactInfo.firstName,
            lastName: contactInfo.lastName,
        };

        if (!contactInfo.email) {
            dispatch(
                setAccountHolderContext({
                    ...accountHolderContext,
                    isExistingEmail: null,
                    userId: null,
                })
            );
            return;
        }

        const response = await userService.getAllUsers(0, 1000, {
            userName: contactInfo.email,
        });

        const {entity} = response;
        const isExistingEmail = entity.length >= 1;
        const userId = entity[0]?.userId || null;

        // if we navigate back from adding an email to the patient's demographic info
        // but loginVerified is still null...
        if (selectedPatientEncounter.loginVerified === null) {
            // and they've already chose a champion
            if (doesChampionExist || selectedPatientChampion) {
                // and the email exists
                if (isExistingEmail) {
                    // email verification wasn't sent... so send it
                    const result = await userService.sendVerificationEmail(
                        userId,
                        selectedPatientEncounter.patientEncounterId
                    );

                    if (result.hasErrors) {
                        dispatch(
                            showErrorStatus(
                                `Email failed to send. Please resend`
                            )
                        );
                    }

                    // update status ioc verified status to pending
                    await dispatch(
                        updateIOC({loginVerified: LoginVerifiedStatus.pending})
                    );

                    dispatch(
                        setAccountHolderContext({
                            ...accountHolderContext,
                            isExistingEmail,
                            userId,
                        })
                    );
                    return;
                } else {
                    // they've chosen a champion,but the emails is not an existing email
                    await dispatch(
                        updateIOC({loginVerified: LoginVerifiedStatus.verified})
                    );

                    dispatch(
                        setAccountHolderContext({
                            ...accountHolderContext,
                            isExistingEmail,
                            userId,
                        })
                    );
                    return;
                }
            }
        }

        dispatch(
            setAccountHolderContext({
                ...accountHolderContext,
                isExistingEmail,
                userId,
            })
        );
    };

    const whoIsTheAccountHolder = (
        patientChampions: PatientChampion[],
        selectedPatient: any
    ) => {
        const guarantor = patientChampions.filter((champion: any) => {
            return champion.isGuarantor;
        });
        if (guarantor.length >= 1) {
            return {
                isPatient: false,
                accountHolder: guarantor[0],
            };
        } else {
            return {
                isPatient: true,
                accountHolder: selectedPatient,
            };
        }
    };

    useEffect(() => {
        if(selectedPatientEncounter.clientId > 0) dispatch(
            getClientFacilities(selectedPatientEncounter.clientId)
        )
    }, [selectedPatientEncounter])

    useEffect(() => {
        // run on mount only
        // selected encounter takes a second to load
        if (isLoadingEncounter) return;
        if (selectedPatientChampion === undefined) return;
        if (!doesChampionExist) return;
        if (hasRunOnce.current) return;

        const {accountHolder, isPatient} = whoIsTheAccountHolder(
            selectedPatientChampion,
            selectedPatient
        );

        setInitialAccountHolderContext(accountHolder.contact, isPatient);
        hasRunOnce.current = true;

        return () => {
            hasRunOnce.current = false;
            dispatch(setAccountHolderContext({type: 'reset'}));
        };
    }, [selectedPatientChampion]);

    async function handleDropdown() {
        const formValues = formRef?.current?.values;
        if (formValues?.instanceOfCareId !== '-1') {
            const selPatientEncounter =
                patientEncounters &&
                patientEncounters.find(
                    (patientEncounter: PatientEncounter) =>
                        parseInt(formValues?.instanceOfCareId) ===
                        patientEncounter?.patientEncounterId
                );

            if (selPatientEncounter?.patientEncounterId) {
                try {
                    await new Promise((resolve, reject) => {
                        asyncFetchPatientEncounter(
                            selPatientEncounter.patientId,
                            selPatientEncounter.patientEncounterId,
                            stateFields.isPreConverted
                        )
                            .then(async (result: any) => {
                                await dispatch(
                                    setSelectedEncounter(
                                        result.payload
                                            .selectedPatientEncounter !==
                                            emptyPatientEncounter
                                            ? result.payload
                                                  .selectedPatientEncounter
                                            : selPatientEncounter
                                    )
                                );
                                resolve(result);
                            })
                            .catch(reject);
                    });
                } catch (error) {
                    // Handle any errors that occur during the asynchronous fetch
                    console.error('Error fetching patient encounter:', error);
                }
            }
        }
    }

    const showInstanceOfCareText = (
        <Box
            display="flex"
            width={'100%'}
            alignItems="center"
            justifyContent="center"
        >
            <span className="title">Add an Instance of Care</span>
        </Box>
    );

    const initialValues = {
        instanceOfCareId:
            selectedPatientEncounter?.patientEncounterId === 0
                ? '-1'
                : selectedPatientEncounter?.patientEncounterId,
        previousInstanceOfCareId:
            selectedPatientEncounter?.patientEncounterId === 0
                ? '-1'
                : selectedPatientEncounter?.patientEncounterId,
    };

    const admissionDate = selectedPatientEncounter?.admissionDt
        ? new Date(selectedPatientEncounter.admissionDt)
        : '';

    const dischargeDate = selectedPatientEncounter?.dischargeDt
        ? new Date(selectedPatientEncounter.dischargeDt)
        : '';
 
    const isSelfPay = selectedPatientEncounter?.payorRisk?.payorRiskId === 3;

    let accountHolderIcon;
    switch (selectedPatientEncounter?.loginVerified) {
        case LoginVerifiedStatus.pending:
            accountHolderIcon = <PendingOutlined sx={{color: '#f2db00'}} />;
            break;
        case LoginVerifiedStatus.verified:
            accountHolderIcon = (
                <CheckCircleOutlineIcon className="green-icon" />
            );
            break;
        case null:
        default:
            accountHolderIcon = (
                <RadioButtonUncheckedIcon className="gray-icon" />
            );
    }

    const iocAccordionContents = (
        <div>
            <Grid container justifyContent="space-between">
                <>
                    <Grid item xs={10} />
                    <Box justifyContent="flex-end">
                        <Grid item xs={2}>
                            <AccessControl
                                rolePageName={RolePageNames.PatientRecords}
                                actionName="Add Instance of Care"
                                renderNoAccess={() => (
                                    <IconButton disabled size="large">
                                        {' '}
                                        <EditIcon className="colorDisabled" />
                                    </IconButton>
                                )}
                            >
                                <IconButton
                                    onClick={() => handleOpenIOCModal(true)}
                                    size="large"
                                >
                                    {' '}
                                    <EditIcon className="icon" />
                                </IconButton>
                            </AccessControl>
                        </Grid>
                    </Box>
                </>
                <HeaderWithDetails
                    header="Provider"
                    details={selectedPatientEncounter?.clientName}
                    width="half"
                />
                <HeaderWithDetails
                    header="Facility"
                    details={selectedPatientEncounter?.facilityName}
                    width="half"
                />
                <HeaderWithDetails
                    header="Client's Patient Account ID"
                    details={selectedPatientEncounter?.clientsPatientAccountId}
                    width="half"
                />
                <HeaderWithDetails
                    header="Client's Patient IOC ID"
                    details={selectedPatientEncounter?.clientsPatientIOCId}
                    width="half"
                />
                <HeaderWithDetails
                    header="Adjusted PFR"
                    details={formatNumberToUSD(
                        selectedPatientEncounter?.adjustedPfrAmt
                    )}
                    width="half"
                    testId= 'current-pfr-amt'
                />
                <HeaderWithDetails
                    header="Type of Care"
                    details={selectedPatientEncounter?.typeOfCare?.typeOfCare}
                    width="half"
                />
                {selectedPatientEncounter?.levelOfCare && (<HeaderWithDetails
                    header="Level of Care"
                    details={selectedPatientEncounter?.levelOfCare}
                    width="half"
                />)}
                <HeaderWithDetails
                    header="Timing Risk"
                    details={
                        selectedPatientEncounter?.timingRisk?.timingRiskName
                    }
                    width="half"
                />
                <HeaderWithDetails
                    header="Payor Risk"
                    details={selectedPatientEncounter?.payorRisk?.payorRiskName}
                    width="half"
                />
                <HeaderWithDetails
                    header="Risk Class"
                    details={selectedPatientEncounter?.riskClass?.riskClassName}
                    width="half"
                />
                <HeaderWithDetails
                    header="Paid to Patient"
                    details={
                        selectedPatientEncounter?.paidToPatient === true
                            ? 'Yes'
                            : 'No'
                    }
                    width="half"
                />
                {admissionDate !== '' ? (
                    <HeaderWithDetails
                        header="Admission"
                        details={Utils.convertDate(admissionDate)}
                        width="half"
                    />
                ) : (
                    <HeaderWithDetails header="Admission" width="half" />
                )}
                {dischargeDate !== '' ? (
                    <HeaderWithDetails
                        header="Discharge"
                        details={Utils.convertDate(dischargeDate)}
                        width="half"
                    />
                ) : (
                    <HeaderWithDetails header="Discharge" width="half" />
                )}
                <Grid item xs={12} sm={6}>
                    <div style={{width: '80%'}}>
                        <TextField
                            select={true}
                            label="Warm Transfer Completed?"
                            name="isWarmTransferCompleted"
                            value={isWarmTransferCompleted}
                            onChange={(event: {target: {value: string}}) => {
                                handleWarmTransfer(event);
                            }}
                            onBlur={() => {}}
                            disabled={!!selectedPatientEncounter?.isConverted}
                        >
                            <MenuItem value="-1">N/A</MenuItem>
                            <MenuItem value={'Yes'}>Yes</MenuItem>
                            <MenuItem value={'No'}>No</MenuItem>
                        </TextField>
                    </div>

                </Grid>
                {selectedPatientEncounter?.pfrEstimateId && isEstimateFromFinAdvisor && !estimateFeatureFlag && (
                    <HeaderWithDetails
                        header="Estimate"
                        details={
                            <IconButton
                                title="View Estimate"
                                style={{fontSize: '14pt', color: '#00508E'}}
                                onClick={() => handleShowEstimateModal()}
                                size="large"
                            >
                                <SummarizeOutlinedIcon /> View Estimate
                            </IconButton>
                        }
                        width="half"
                    />
                )}
                {selectedPatientEncounter?.pfrEstimateId && newEstimate && estimateFeatureFlag && (
                    <HeaderWithDetails
                        header="Estimate"
                        details={
                            <IconButton
                                title="View Estimate"
                                style={{fontSize: '14pt', color: '#00508E'}}
                                onClick={() => handleNewEstimateView(selectedPatientEncounter?.pfrEstimateId!)}
                                size="large"
                            >
                                <SummarizeOutlinedIcon /> View Estimate
                            </IconButton>
                        }
                        width="half"
                    />
                )}
                <HeaderWithDetails
                    header="Financial Clearance"
                    width="full"
                    details={
                        <Status
                            typographyVariant="h3"
                            statusColor={
                                selectedPatientEncounter?.finClearanceStatus
                                    ? getFinancialStatusColor(
                                          selectedPatientEncounter.finClearanceStatus
                                      )
                                    : StatusColors.blue
                            }
                            text={
                                selectedPatientEncounter?.finClearanceStatus ||
                                'New'
                            }
                        />
                    }
                />
                <HeaderWithDetails
                    header="Workflow Status"
                    width="full"
                    details={
                        <Status
                            typographyVariant="h3"
                            text={`
              ${selectedPatientEncounter?.workflow?.workflowStatus
                  ?.workflowStatusDesc}${
                  selectedPatientEncounter?.workflow?.workflowSubStatus
                      ?.workflowSubStatusDesc
                      ? ' - ' +
                        selectedPatientEncounter?.workflow?.workflowSubStatus
                            ?.workflowSubStatusDesc
                      : ''
              }`}
                            statusColor={
                                selectedPatientEncounter?.workflow
                                    ?.workflowSubStatus
                                    ?.workflowSubStatusDesc === 'None'
                                    ? getPatientStatusByStatusId(
                                          selectedPatientEncounter?.workflow
                                              ?.workflowStatus
                                              ?.workflowStatusId,
                                          StatusType['primary-status'],
                                          selectedPatientEncounter?.workflow
                                              ?.workflowId
                                      ).color
                                    : getPatientStatusByStatusId(
                                          selectedPatientEncounter?.workflow
                                              ?.workflowSubStatus
                                              ?.workflowSubStatusId,
                                          StatusType['sub-status'],
                                          selectedPatientEncounter?.workflow
                                              ?.workflowId
                                      ).color
                            }
                        />
                    }
                />
            </Grid>
            <CustomizeableAccordion
                icon={
                    authorizationDocumentStatus?.overallDocumentStatus ===
                    SignatureStatus.SignatureCompleted ? (
                        <CheckCircleOutlineIcon className="green-icon" />
                    ) : authorizationDocumentStatus?.overallDocumentStatus ===
                          'No Documents' ||
                      !authorizationDocumentStatus?.overallDocumentStatus ? (
                        <RadioButtonUncheckedIcon className="gray-icon" />
                    ) : (
                        <PendingOutlined sx={{color: '#f2db00'}} />
                    )
                }
                headerText="Document Statuses"
                details={<DocumentsStatusAccordion />}
            />
            <CustomizeableAccordion
                icon={accountHolderIcon}
                headerText="Account Holder Status"
                details={
                    <AccountStatusAccordian
                        encounter={selectedPatientEncounter}
                        firstName={accountHolderContext.firstName}
                        lastName={accountHolderContext.lastName}
                        userName={accountHolderContext.userName}
                        isPatient={accountHolderContext.isPatient}
                        userId={accountHolderContext.userId}
                    />
                }
            />
        </div>
    );

    const instanceOfCareOverview = (
        <CustomizeableAccordion
            icon={<FolderOpenIcon className="gray-icon" />}
            headerText="Overview"
            details={doesSelectedPatientEncounterExist && iocAccordionContents}
            isDefaultExpanded
        />
    );

    const insuranceView = (
        <CustomizeableAccordion
            icon={
                doesInsuranceExist ? (
                    <CheckCircleOutlineIcon className="green-icon" />
                ) : (
                    <RadioButtonUncheckedIcon className="gray-icon" />
                )
            }
            headerText="Insurance"
            details={doesInsuranceExist && <InsuranceAccordionContents />}
            openModalCallback={insuranceAccordionModalCallback}
            rolePageName={RolePageNames.PatientRecords}
            actionName="Add Instance of Care"
            isStart
        />
    );

    const vobAccordion = (
        <CustomizeableAccordion
            icon={
                (doesVobExist && vob.isValid) ? (
                    <CheckCircleOutlineIcon className="green-icon" />
                ) : (
                    <RadioButtonUncheckedIcon className="gray-icon" />
                )
            }
            headerText="Verification Of Benefits"
            details={
                (doesVobExist && vob.isValid) && (
                    <VerificationOfBenefitsAccordionContents
                        vobClassification={vobClassification}
                        facilityPayersState={facilityPayersState}
                    />
                )
            }
            openModalCallback={() => {
                setIsVobModalOpen(true);
            }}
            rolePageName={RolePageNames.PatientRecords}
            actionName="Add Instance of Care"
            isStart
        />
    );

    const locAccordion = (
        <CustomizeableAccordion
            icon={
                doesLocExist ? (
                    <CheckCircleOutlineIcon className="green-icon" />
                ) : (
                    <RadioButtonUncheckedIcon className="gray-icon" />
                )
            }
            headerText="Levels of Care"
            details={
                doesLocExist && (
                    <LevelsOfCareAccordionContents/>
                )
            }
            openModalCallback={() => {
                setIsLocModalOpen(true);
            }}
            rolePageName={RolePageNames.PatientRecords}
            actionName="Add Instance of Care"
            isStart
            isDisabled={(!doesVobExist && selectedPatientEncounter.payorRisk?.payorRiskId !== 3) || selectedPatientEncounter.workflow.workflowId ===3}
        />
    );

    const patientChampionsView = (
        <CustomizeableAccordion
            icon={
                doesChampionExist ||
                selectedPatientEncounter?.patientChampion?.length > 0 ||
                selectedPatientEncounter?.hasChampions === false ? (
                    <CheckCircleOutlineIcon className="green-icon" />
                ) : (
                    <RadioButtonUncheckedIcon className="gray-icon" />
                )
            }
            details={
                ((doesSelectedPatientEncounterExist && doesChampionExist) ||
                    selectedPatientEncounter?.hasChampions === false) && (
                    <ChampionAccordionContents />
                )
            }
            headerText="Patient Champions"
            openModalCallback={championAccordionModalCallback}
            rolePageName={RolePageNames.PatientRecords}
            actionName="Apply Payment Program"
            isStart
        />
    );

    const paymentProgramView = (
        <CustomizeableAccordion
            hasScroll
            icon={
                (doesPaymentProgramExist &&
                    doesDownPaymentExist &&
                    doesRecurringPaymentExist) ||
                selectedPatientEncounter?.isConverted ? (
                    <CheckCircleOutlineIcon className="green-icon" />
                ) : (
                    <RadioButtonUncheckedIcon className="gray-icon" />
                )
            }
            details={
                doesPaymentProgramExist && (
                    <div className="w-100">
                        <PaymentProgramAccordionContents />
                    </div>
                )
            }
            headerText="Payment Program"
            openModalCallback={paymentProgramsModalCallback}
            expandCallback={handlePaymentProgramAccordionExpand}
            rolePageName={RolePageNames.PatientRecords}
            actionName="Apply Payment Program"
            isStart
        />
    );

    const documentsView = (
        <CustomizeableAccordion
            icon={<InsertDriveFileIcon className="gray-icon" />}
            details={
                <div className="w-100">
                    <DocumentsAccordionContents
                        documents={selectedPatientEncounter?.patientDocument}
                        selectedEncounter={selectedPatientEncounter}
                        s3Documents={patientS3Documents}
                    />
                </div>
            }
            hasScroll
            headerText="Documents"
            expandCallback={handleDocumentAccordionExpand}
            openModalCallback={() => {}}
        />
    );

    const paymentStatusView = (
        <CustomizeableAccordion
            icon={<AttachMoneyIcon className="gray-icon" />}
            details={
                <div className="w-100">
                    <PaymentStatusAccordionContents
                        setBalanceAdjustmentModalOpen={
                            balanceAdjustmentModalCallback
                        }
                        documents={selectedPatientEncounter?.patientDocument}
                    />
                </div>
            }
            expandCallback={handleDocumentAccordionExpand}
            headerText="Payment Status"
        />
    );

    const transactionsView = (
        <CustomizeableAccordion
            hasScroll
            icon={<CompareArrowsIcon className="gray-icon" />}
            details={
                <div className="w-100">
                    {selectedPatientEncounter?.patientPaymentProgram?.length >
                    0 ? (
                        <TransactionsAccordionContents
                            selectedEncounter={selectedPatientEncounter}
                        />
                    ) : (
                        <Typography variant="body1">
                            There are no transactions available
                        </Typography>
                    )}
                </div>
            }
            headerText="Transactions"
            expandCallback={handleTransactionsAccordionExpand}
            openModalCallback={() => {}}
        />
    );

    const instanceOfCareDetails = patientEncounters && (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    onSubmit={() => {}}
                    validate={() => {
                        setTimeout(() => {
                            handleDropdown();
                        }, 50);
                    }} // helps ensure formik state is up-to-date before using it in handleDropdown.
                >
                    {(formik: any) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="mb-4" data-testid="select-ioc">
                                    <TextField
                                        select={true}
                                        error={
                                            formik.touched[
                                                'instanceOfCareId'
                                            ] &&
                                            formik.errors['instanceOfCareId']
                                        }
                                        label="Instance Of Care"
                                        name="instanceOfCareId"
                                        value={formik.values.instanceOfCareId}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <MenuItem value="-1">
                                            Select Instance Of Care
                                        </MenuItem>
                                        {patientEncounters.map(
                                            (encounter: PatientEncounter) => (
                                                <MenuItem
                                                    key={
                                                        encounter?.patientEncounterId
                                                    }
                                                    value={
                                                        encounter?.patientEncounterId
                                                    }
                                                >
                                                    {`${
                                                        encounter?.admissionDt
                                                            ? Utils.convertDate(
                                                                  new Date(
                                                                      encounter?.admissionDt
                                                                  )
                                                              )
                                                            : ''
                                                    }${
                                                        encounter?.admissionDt
                                                            ? ' - '
                                                            : ' '
                                                    }${encounter?.patientEncounterId}`}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>

                {selectedPatientEncounter && (
                    <div>
                        {instanceOfCareOverview}
                        {isSelfPay
                            ? null
                            : doesInsuranceExist
                            ? insuranceView
                            : null}
                        {isSelfPay ? null : vobAccordion}
                        {locAccordion}
                        {patientChampionsView}
                        {paymentProgramView}
                        {documentsView}
                        {selectedPatientEncounter?.isConverted &&
                            paymentStatusView}
                        {transactionsView}
                    </div>
                )}
            </Grid>
            <Grid item xs={4}>
                <Paper>
                    <div className="px-4 py-4">
                        <Comments page={commentsPage.patients} />
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );

    return (
        <>
            {isLoadingEncounter || isLoadingVOB ? (
                <LoadingOverlay />
            ) : (
                <div className="icon-colors">
                    <Box
                        display="flex"
                        width={'100%'}
                        alignItems={'center'}
                        justifyContent={'flex-end'}
                        className="pb-4"
                    >
                        <AccessControl
                            rolePageName={RolePageNames.PatientRecords}
                            actionName="Add Instance of Care"
                            renderNoAccess={() => (
                                <Button disabled>Add Instance of Care</Button>
                            )}
                        >
                            <Button onClick={() => handleOpenIOCModal(false)}>
                                Add Instance Of Care
                            </Button>
                        </AccessControl>
                    </Box>
                    {!patientEncounters && showInstanceOfCareText}
                    {patientEncounters && instanceOfCareDetails}
                    {patientModalOpenStatus.isIOCModalOpen && (
                        <InstanceOfCareModal
                            open={patientModalOpenStatus.isIOCModalOpen}
                            isEdit={isIOCModalEditMode}
                            clientId={selectedPatientEncounter?.clientId}
                            handleModalCancel={handleIOCModalCancel}
                            handleModalSubmit={handleIOCModalSubmit}
                        />
                    )}
                    {isInsuranceModalOpen && (
                        <InsuranceModal
                            open={isInsuranceModalOpen}
                            handleModalClose={handleInsuranceModalClose}
                            isEdit={false}
                        />
                    )}
                    {isVobModalOpen && (
                        <FinpassVOBModal
                            open={isVobModalOpen}
                            isEdit={isIOCModalEditMode}
                            classifications={vobClassification}
                            facilityPayersState={facilityPayersState}
                            handleVOBModalCancel={handleVOBModalCancel}
                            handleVOBModalSubmit={handleVOBModalCancel}
                        />
                    )}
                    {isLocModalOpen && (
                          <FinpassLevelOfCareModal
                              open={isLocModalOpen}
                              clientId={selectedPatientEncounter.clientId}
                              facilityId={selectedPatientEncounter.facilityId}
                              facilityName={facilities?.flat().find((el) => el.facilityId === selectedPatientEncounter.facilityId)?.facilityName}
                              quoteMethod={estimate?.quoteMethod}
                              handleFinpassLevelOfCareModalClose={handleLocModalClose}
                              />
                    )}
                    {isChampionModalOpen && (
                        <ChampionModal
                            open={isChampionModalOpen}
                            handleModalClose={handleChampionModalClose}
                            championsIndex={
                                selectedPatientEncounter?.patientChampion
                                    ?.length
                                    ? selectedPatientEncounter.patientChampion
                                          .length
                                    : 0
                            }
                            isEdit={false}
                        />
                    )}
                    {isPaymentProgramsModalOpen && (
                        <PaymentProgramsModal
                            open={isPaymentProgramsModalOpen}
                            handleModalClose={handlePaymentProgramsModalClose}
                            isEdit={false}
                        />
                    )}
                    {isPaymentProgramsV2ModalOpen && (
                        <PaymentProgramsV2Modal
                            open={isPaymentProgramsV2ModalOpen}
                            handleModalClose={handlePaymentProgramsV2ModalClose}
                            isEdit={false}
                        />
                    )}
                    {isBalanceAdjustmentModalOpen && (
                        <BalanceAdjustmentModal
                            open={isBalanceAdjustmentModalOpen}
                            balanceAdjustmentModalValues={
                                balanceAdjustmentModalValues
                            }
                            handleModalClose={handleBalanceAdjustmentModalClose}
                            handleModalSave={handleBalanceAdjustmentModalSave}
                        />
                    )}
                    <BalanceAdjustmentConfirmationModal
                        open={isBalanceAdjustmentConfirmModalOpen}
                        balanceAdjustmentModalValues={
                            balanceAdjustmentModalValues
                        }
                        handleChangeBalanceAdjustment={
                            handleChangeBalanceAdjustment
                        }
                        handleConfirm={handleConfirmBalanceAdjustment}
                    />

                    {isEstimatorSummaryOpen && (
                        <EstimateSummaryModal
                            open={isEstimatorSummaryOpen}
                            estimateId={selectedPatientEncounter?.pfrEstimateId}
                            handleModalCancel={handleEstimatorSummaryModalClose}
                        />
                    )}

                    {lockingModalOpenStatus.isInUseModalOpen_overview && (
                        <LockModal
                            open={
                                lockingModalOpenStatus.isInUseModalOpen_overview
                            }
                            title="Instance of Care In Use"
                            subTitle1={`A release request will be sent to ${selectedPatientEncounter.lockedByUserId}`}
                            subTitle2={`Access will be granted in ${countdownMax} seconds or less`}
                            hasCountdown={false}
                            countdownSeconds={countdownMax}
                            primaryButtonType="primary"
                            primaryButtonText="Request Release"
                            handlePrimaryButton={handleRequestReleaseOk}
                            secondaryButtonType="secondary"
                            secondaryButtonText="Cancel"
                            handleSecondaryButton={handleRequestReleaseCancel}
                            icon={<LockIcon />}
                            modalType="info"
                            justifyButtons="flex-end"
                            primaryButtonDisabled={
                                isRequestReleaseButtonDisabled
                            }
                        />
                    )}

                    {lockingModalOpenStatus.isRequestDeniedModalOpen_overview && (
                        <LockModal
                            open={
                                lockingModalOpenStatus.isRequestDeniedModalOpen_overview
                            }
                            title="Request Denied"
                            subTitle1={`${lockRequestedById} will retain access to this record`}
                            hasCountdown={false}
                            primaryButtonType="secondary"
                            primaryButtonText="Close"
                            handlePrimaryButton={handleRequestDeniedClose}
                            icon={<LockIcon />}
                            modalType="error"
                            justifyButtons="flex-end"
                        />
                    )}

                    {lockingModalOpenStatus.isLockRequestedModalOpen_overview && (
                        <LockModal
                            open={
                                lockingModalOpenStatus.isLockRequestedModalOpen_overview
                            }
                            title="Instance of Care Requested"
                            subTitle1={`${lockRequestedById} requested this record`}
                            subTitle2={`Access will be granted in ${countdownMax} seconds or less`}
                            hasCountdown
                            countdownSeconds={countdownMax}
                            primaryButtonType="primary"
                            primaryButtonText="Release Record"
                            handlePrimaryButton={handleReleaseRecordOk}
                            secondaryButtonType="danger"
                            secondaryButtonText="Deny Request"
                            handleSecondaryButton={() =>
                                dispatch(
                                    denyRequestOk({
                                        lockablePatientRecordSection:
                                            LockablePatientRecordSection.overview,
                                    })
                                )
                            }
                            icon={<WarningRoundedIcon />}
                            modalType="warning"
                            justifyButtons="space-between"
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default InstanceOfCare;
