import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { formatNumberToUSD } from '../calculators';
import { admissionsAdvisorUtils } from '../../admissions-advisor/utils/admission-advisor-utils';
import { EstimateSummary } from '@finpay/estimation-types';

interface PriorCareSummaryTableProps {
  priorCareSummary?: EstimateSummary;
}

const PriorCareSummaryTable: React.FC<PriorCareSummaryTableProps> = ({ priorCareSummary }) => {
  const getDeductibleSpentPct = () => {
    return admissionsAdvisorUtils.getDeductibleSpentPct(
      priorCareSummary?.priorCareDeductibleSpent || 0,
      priorCareSummary?.remainingDeductibleFromVob || 0
    );
  };

  const getOopSpentPct = () => {
    return admissionsAdvisorUtils.getOopSpentPct(
      priorCareSummary?.includeOOPmax || false,
      priorCareSummary?.oopMaxFromVob || 0,
      priorCareSummary?.remainingOopMaxFromVob || 0
    );
  };

  if (!priorCareSummary) {
    return null; // Or you could return a message saying no data is available
  }

  return (
    <Table size="small">
      <TableBody>
        <TableRow className="py-3 light-green-row">
          <TableCell>Estimated Prior Care Adjusted Charges</TableCell>
          <TableCell test-id="prior-care-summary-modal-covered-charges" align="right">
            {formatNumberToUSD(priorCareSummary.priorCareCoveredCharges || 0)}
          </TableCell>
        </TableRow>
        <TableRow className="py-3 light-green-row">
          <TableCell>Estimated Prior Care Length of Stay</TableCell>
          <TableCell test-id="prior-care-summary-modal-los" align="right">
            {priorCareSummary.priorCareLos || 0}
          </TableCell>
        </TableRow>
        <TableRow className="py-3 light-green-row">
          <TableCell>Estimated Prior Care PFR</TableCell>
          <TableCell test-id="prior-care-summary-modal-pfr" align="right">
            {formatNumberToUSD(priorCareSummary.priorCareTotalPFR || 0)}
          </TableCell>
        </TableRow>
        <TableRow className="py-3 light-green-row">
          <TableCell>{`Estimated Prior Care Deductible Spent (${getDeductibleSpentPct()})`}</TableCell>
          <TableCell test-id="prior-care-summary-modal-deductible-spent" align="right">
            {formatNumberToUSD(priorCareSummary.priorCareDeductibleSpent || 0)}
          </TableCell>
        </TableRow>
        <TableRow className="py-3 light-green-row">
          <TableCell>{`Estimated Prior Care Max OOP Met (${getOopSpentPct()})`}</TableCell>
          <TableCell test-id="prior-care-summary-modal-oop-met" align="right">
            {formatNumberToUSD(priorCareSummary.metOopMaxAfterPriorCare || 0)}
          </TableCell>
        </TableRow>
        {(priorCareSummary.remainingOopMaxAfterPriorCare || 0) > 0 && (
          <TableRow className="py-3 light-green-row">
            <TableCell>Estimated Prior Care Max OOP Remaining</TableCell>
            <TableCell test-id="prior-care-summary-modal-oop-remaining" align="right">
              {priorCareSummary.includeOOPmax
                ? formatNumberToUSD(priorCareSummary.remainingOopMaxAfterPriorCare || 0)
                : 'N/A'}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default PriorCareSummaryTable;