import {
  phoneNumberValidator,
  websiteValidator,
  zipCodeValidator,
  phoneNumberOptValidator,
  SSNOptValidator,
  websiteWwwRequiredValidator,
  notNegativeOneValidator,
  SSNValidatorTesting,
  atLeastOneCharsLongOrBlank,
  zipCodeValidatorOptional,
  atLeastThreeCharsLongOrBlank,
  taxIdValidator,
} from "../misc/regex";
import * as Yup from "yup";
import { TypeOfChange } from "../../patient/components/misc/adjustment-reasons";
import { eighteenYearsAgo } from "../calculators";
import { Utils } from "../utils";

const makeInOoNetworkTest = (
  isInNetwork: boolean,
  isIndividualPolicy: boolean
): Yup.TestFunction => {
  const prefix = isInNetwork ? "inNetw" : "ooNetw";
  const alternateModifier = isIndividualPolicy ? "Family" : "";
  const alternateFields = [
    "OopMax",
    "OopMaxRemaining",
    "Deductible",
    "DeductibleRemaining",
  ].map((fld) => `${prefix}${alternateModifier}${fld}`);
  return (value, context) => {
    if (
      context.parent.policyInNetwork !== isInNetwork ||
      typeof value === "number"
    ) {
      return true;
    }
    return alternateFields.every(
      (fld) => typeof context.parent[fld] === "number"
    );
  };
};

const makeCustomConfigTest = (
  fieldName: "bannerText" | "referenceNumberHelpText" | "referenceNumberName"
): Yup.TestFunction => {
  const others = [
    "bannerText",
    "referenceNumberName",
    "referenceNumberHelpText",
  ].filter((value) => value !== fieldName);
  return (value, context) => {
    let isRequired = Utils.isPopulatedValue(
      context.parent.payNowExternalAccount
    );
    if (others.some((other) => Utils.isPopulatedValue(context.parent[other]))) {
      isRequired = true;
    }
    return Utils.isPopulatedValue(value) || !isRequired;
  };
};

export const commonFields = {
  // Note: All validation of ".min(1) effectively makes the field required without using ".required(). Does so silently without adding a "Required" message. Only needed for fields that don't need add'l validation
  REQUIRED_STRING: Yup.string().min(1), // Generic. Used for fields that only need to be required, but isn't included in this 'commonFields' object.
  STREET_ADDRESS_1: Yup.string()
    .min(3)
    .matches(
      atLeastThreeCharsLongOrBlank,
      "Street Address 1 must be at least 3 characters long"
    ),
  STREET_ADDRESS_1_OPTIONAL: Yup.string().matches(
    atLeastThreeCharsLongOrBlank,
    "Street Address 1 must be at least 3 characters long"
  ),
  STREET_ADDRESS_2: Yup.string().matches(
    atLeastThreeCharsLongOrBlank,
    "Street Address 2 must be at least 3 characters long"
  ),
  CITY: Yup.string()
    .min(1)
    .matches(
      atLeastOneCharsLongOrBlank,
      "City must be at least 1 characters long"
    ),
  CITY_OPTIONAL: Yup.string().matches(
    atLeastOneCharsLongOrBlank,
    "City must be at least 1 characters long"
  ),
  STATE: Yup.string()
    .min(1)
    .matches(notNegativeOneValidator, "Must select a value"),
  STATE_OPTIONAL: Yup.string().matches(
    atLeastOneCharsLongOrBlank,
    "Must select a value"
  ),
  ZIP: Yup.string()
    .trim()
    .min(5)
    .matches(zipCodeValidator, "Must be a number 5 characters long"),
  ZIP_OPTIONAL: Yup.string().matches(
    zipCodeValidatorOptional,
    "Must be a number 5 characters long"
  ),
  WEBSITE: Yup.string()
    .matches(websiteWwwRequiredValidator, '"www." is required')
    .matches(websiteValidator, "Website Format is Invalid"),
  FIRST_NAME: Yup.string().min(1).max(50, "Must be 50 characters or less"),
  MIDDLE_NAME: Yup.string(),
  LAST_NAME: Yup.string().min(1).max(50, "Must be 50 characters or less"),
  PATIENT_NAME_OPT: Yup.string()
    .min(0)
    .max(50, "Must be 50 characters or less"),
  EMAIL: Yup.string().min(1).email("Email Format is Invalid"),
  RETYPE_EMAIL: Yup.string().oneOf(
    [Yup.ref("email"), null],
    "Email and Email Confirm must Match"
  ), // first email field must be called 'email'
  PHONE: Yup.string().matches(phoneNumberValidator, "Phone format is Invalid"),
  RETYPE_PHONE: Yup.string()
    .matches(phoneNumberValidator, "Invalid format, please try again")
    .oneOf(
      [Yup.ref("phoneNumber"), null],
      "Phone Number and Confirm Phone Number fields must match, please try again"
    ), // first mobile phone field must be called 'phoneNumber'
  PHONE_OPT: Yup.string()
    .matches(phoneNumberOptValidator, "Phone format is Invalid")
    .nullable(),
  MOBILE_PHONE: Yup.string()
    .min(1)
    .matches(phoneNumberValidator, "Mobile Phone Format is Invalid"),
  RETYPE_MOBILE_PHONE: Yup.string()
    .matches(phoneNumberValidator, "Invalid format, please try again")
    .oneOf(
      [Yup.ref("mobilePhone"), null],
      "Mobile Phone and Mobile Phone Confirm must Match"
    ), // first mobile phone field must be called 'mobilePhone'
  DATE: Yup.string().min(1), // TODO: proper validation
  OPTIONAL_DATE: Yup.string().nullable(),
  SSN: Yup.string().matches(SSNValidatorTesting, "SSN format invalid"), //This is just for testing purposes, will change to the "Real" SNN Validatior
  SSN_OPT: Yup.string().matches(SSNOptValidator, "SSN format invalid"),
  TAX_ID: Yup.string()
    .matches(taxIdValidator, "Invalid tax Id format")
    .required("This field is required"),
  REQUIRED_DROPDOWN: Yup.string()
    .min(1)
    .matches(notNegativeOneValidator, "Must select a value"), // use when you want a dropdown to be 'required'.
  ROUTING_NUMBER: Yup.string().matches(
    /[\d]{2}-?[\d]{7}/,
    "Invalid Routing Number Format"
  ),
  ACCOUNT_NUMBER: Yup.string().matches(/[\d]{5,17}/, "Invalid Account Format"), // US bank accounts can be from 6 to 17 digits. Source: https://bit.ly/3AWKlI5
  RETYPE_ACCOUNT_NUMBER: Yup.string().oneOf(
    [Yup.ref("accountNumber"), null],
    "Account Mismatch"
  ), // first account number field must be called 'accountNumber',
  ONLY_NUMERIC_VALUES: Yup.string()
    .min(1)
    .matches(/^[0-9]*([.][0-9]*)?$/, "Invalid number"),
  EIGHTEEN_YEARS_OR_OLDER: Yup.date().max(
    eighteenYearsAgo(),
    "Must be at least 18 yrs old"
  ),
  REQUIRED_NUMBER_VOB: Yup.number()
    .nullable(true)
    .required("This field is required"),
  REQUIRED_NUMBER: Yup.number().required("This field is required"),
  REQUIRED_POSITIVE_NUMBER: Yup.number()
    .positive("Must be a positive number")
    .required("This field is required"),
  REQUIRED_POSITIVE_NUMBER_ZERO_INCLUSIVE: Yup.number()
    .min(0, "Must be a positive number")
    .required("This field is required"),
  NOT_REQUIRED_NULLABLE_NUMBER: Yup.number()
    .nullable(true)
    .notRequired()
    .transform((_, val) => (val === Number(val) ? val : null)),

  IN_NETWORK_INDIVIDUAL_POLICY_ITEM: Yup.number()
    .nullable(true)
    .notRequired()
    .transform((_, val) => (val === Number(val) ? val : null))
    .test(
      "inNetworkIndividualPolicyItem",
      "This field is required",
      makeInOoNetworkTest(true, true)
    ),

  OUT_OF_NETWORK_INDIVIDUAL_POLICY_ITEM: Yup.number()
    .nullable(true)
    .notRequired()
    .transform((_, val) => (val === Number(val) ? val : null))
    .test(
      "inNetworkIndividualPolicyItem",
      "This field is required",
      makeInOoNetworkTest(false, true)
    ),

  IN_NETWORK_FAMILY_POLICY_ITEM: Yup.number()
    .nullable(true)
    .notRequired()
    .transform((_, val) => (val === Number(val) ? val : null))
    .test(
      "inNetworkIndividualPolicyItem",
      "This field is required",
      makeInOoNetworkTest(true, false)
    ),

  OUT_OF_NETWORK_FAMILY_POLICY_ITEM: Yup.number()
    .nullable(true)
    .notRequired()
    .transform((_, val) => (val === Number(val) ? val : null))
    .test(
      "inNetworkIndividualPolicyItem",
      "This field is required",
      makeInOoNetworkTest(false, false)
    ),
};

export const location = {
  streetAddress1: commonFields.STREET_ADDRESS_1,
  streetAddress2: commonFields.STREET_ADDRESS_2,
  city: commonFields.CITY,
  state: commonFields.REQUIRED_DROPDOWN,
  zipCode: commonFields.ZIP,
};

export const clientEntity = {
  companyName: Yup.string()
    .trim()
    .min(3, "Company Name must be at least 3 characters long"),
  website: commonFields.WEBSITE,
  ...location,
};

export const clientContact = {
  firstName: commonFields.FIRST_NAME,
  lastName: commonFields.LAST_NAME,
  email: commonFields.EMAIL,
  retypeEmail: commonFields.RETYPE_EMAIL,
  phone: commonFields.PHONE,
  mobilePhone: commonFields.PHONE,
  retypeMobilePhone: commonFields.RETYPE_MOBILE_PHONE,
};

export const userForm = {
  firstName: commonFields.FIRST_NAME,
  lastName: commonFields.LAST_NAME,
  email: commonFields.EMAIL,
  confirmEmail: commonFields.RETYPE_EMAIL,
  phoneNumber: commonFields.PHONE,
  confirmPhone: commonFields.RETYPE_PHONE,
  role: Yup.string(),
};

export const commonPatientInfo = {
  firstName: commonFields.FIRST_NAME,
  lastName: commonFields.LAST_NAME,
  email: commonFields.EMAIL,
  retypeEmail: commonFields.RETYPE_EMAIL,
  firstPhone: commonFields.PHONE,
  secondPhone: commonFields.PHONE_OPT,
  leaveVoicemail: Yup.boolean(),
  receiveSMSMessages: Yup.boolean(),
};

export const commonAddressInfo = {
  streetAddress1: commonFields.STREET_ADDRESS_1,
  streetAddress2: commonFields.STREET_ADDRESS_2,
  city: commonFields.CITY,
  state: commonFields.REQUIRED_DROPDOWN,
  zipCode: commonFields.ZIP,
};

export const commonPatientInfoExtras = {
  socialSecurityNumber: commonFields.SSN_OPT,
  isCurrentlyEmployed: Yup.boolean(),
  salary: Yup.string().when("isCurrentlyEmployed", {
    is: (val: boolean) => val,
    then: commonFields.REQUIRED_STRING,
    otherwise: Yup.string(),
  }),
  employerName: Yup.string().when("isCurrentlyEmployed", {
    is: (val: boolean) => val,
    then: commonFields.REQUIRED_STRING,
    otherwise: Yup.string(),
  }),
  yearsAtEmployer: Yup.string().when("isCurrentlyEmployed", {
    is: (val: boolean) => val,
    then: commonFields.REQUIRED_STRING,
    otherwise: Yup.string(),
  }),
};

export const patientInfo = {
  clientId: commonFields.REQUIRED_DROPDOWN,
  ...commonPatientInfo,
  ...commonAddressInfo,
  ...commonPatientInfoExtras,
  dateOfBirth: Yup.date().required("Required"),
};

export const instanceOfCare = {
  provider: commonFields.REQUIRED_DROPDOWN,
  facility: commonFields.REQUIRED_DROPDOWN,
  clientsPatientAccountId: Yup.string().nullable(),
  clientsPatientIOCId: Yup.string().nullable(),
  pfrAmt: commonFields.REQUIRED_STRING,
  timingRisk: commonFields.REQUIRED_DROPDOWN,
  payorRisk: commonFields.REQUIRED_DROPDOWN,
  riskClass: commonFields.REQUIRED_DROPDOWN,
  typeOfCare: commonFields.REQUIRED_DROPDOWN,
  admissionDt: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
  dischargeDt: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr)),
};

export const pesScriptSchema = {
  scriptUrl: commonFields.REQUIRED_STRING,
  riskClass: commonFields.REQUIRED_DROPDOWN,
};

export const authDocsSchema = Yup.object().shape({
  documentUrl: Yup.string()
    .url("Please enter a valid Document URL. Example: https://finpay.com")
    .test(
      "http-protocol",
      "URL must start with http:// or https://",
      (value) =>
        !value || value.startsWith("http://") || value.startsWith("https://")
    )
    .required(),
});

export const crmSchema = Yup.object({
  crmTypeId: Yup.number().positive().required("Required"),
  isIntEnabled: Yup.boolean(),
  isPolledInt: Yup.boolean(),
  isEventInt: Yup.boolean(),
  apiUrl: Yup.string().required("Required"),
  apiUserName: Yup.string().required("Required"),
  apiPassword: Yup.string().required("Required"),
  preadmitTypeId: Yup.number().positive().required("Required"),
  admitTypeId: Yup.number().positive().required("Required"),
  isAdmitIntEnabled: Yup.boolean(),
  isPolledAdmitInt: Yup.boolean(),
  isEventAdmitInt: Yup.boolean(),
  apiFinpayId: Yup.string(),
  apiFinpayToken: Yup.string().nullable(),
  oauthGrantType: Yup.mixed(),
  oauthToken: Yup.string().nullable(),
  authUrl: Yup.string().nullable(),
  accessTokenUrl: Yup.string().nullable(),
  tokenExpireDt: new Yup.DateSchema(),
});

export const changeStatus = {
  workflowStatusId: commonFields.REQUIRED_DROPDOWN,
};

export const insurance = {
  provider: commonFields.REQUIRED_STRING,
  plan: Yup.string(),
  inNetwork: Yup.boolean().required("Required"),
  deductible: commonFields.ONLY_NUMERIC_VALUES,
  remainingDeductible: commonFields.ONLY_NUMERIC_VALUES,
  familyDeductible: commonFields.ONLY_NUMERIC_VALUES,
  remainingFamilyDeductible: commonFields.ONLY_NUMERIC_VALUES,
  percentOrDollars: Yup.string(), // is required if there is a value for 'coinsurance'. Invalidate form based on this logic in the component. Cannot do here or there will be 'cyclic dependency' error
  coinsurance: Yup.string()
    .transform((value) => Number(value))
    .when("percentOrDollars", (percentOrDollars: string) => {
      if (percentOrDollars === "%") {
        return Yup.number().max(100, "Maximum of 100%");
      }
      return Yup.string();
    }),
  subjectToCopay: Yup.boolean().required("Required"),
  copayAmt: commonFields.ONLY_NUMERIC_VALUES,
  maxDays: commonFields.ONLY_NUMERIC_VALUES,
  deductibleIncluded: Yup.boolean().required("Required"),
  oopMax: commonFields.ONLY_NUMERIC_VALUES,
  remainingOopMax: commonFields.ONLY_NUMERIC_VALUES,
  familyOopMax: commonFields.ONLY_NUMERIC_VALUES,
  remainingFamilyOopMax: commonFields.ONLY_NUMERIC_VALUES,
  policyNumber: Yup.string(),
  groupNumber: Yup.string(),
};

export const championInfo = {
  includePatientChampions: Yup.boolean(),
  relationshipToPatient: commonFields.REQUIRED_DROPDOWN,
  isGuarantor: Yup.boolean(),
  ...commonPatientInfo,
  ...commonPatientInfoExtras,
  streetAddress1: Yup.string().when("isGuarantor", {
    is: (val: boolean) => val,
    then: commonFields.STREET_ADDRESS_1,
    otherwise: commonFields.STREET_ADDRESS_1_OPTIONAL,
  }),
  streetAddress2: commonFields.STREET_ADDRESS_2,
  city: Yup.string().when("isGuarantor", {
    is: (val: boolean) => val,
    then: commonFields.CITY,
    otherwise: commonFields.CITY_OPTIONAL,
  }),
  state: Yup.string().when("isGuarantor", {
    is: (val: boolean) => val,
    then: commonFields.STATE,
    otherwise: commonFields.STATE_OPTIONAL,
  }),
  zipCode: Yup.string().when("isGuarantor", {
    is: (val: boolean) => val,
    then: commonFields.ZIP,
    otherwise: commonFields.ZIP_OPTIONAL,
  }),
  dateOfBirth: Yup.date().when("isGuarantor", {
    is: (val: boolean) => val,
    then: commonFields.EIGHTEEN_YEARS_OR_OLDER.required("requuired"),
    otherwise: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr)),
  }),
};

export const championsToggle = {
  includePatientChampions: Yup.boolean(),
};

export const preauthorizedView = {
  preauthorizedTerm: commonFields.REQUIRED_STRING,
};

export const customView = {
  recurring: Yup.boolean(),
  customTerm: commonFields.REQUIRED_STRING,
  recurringPayment: commonFields.REQUIRED_POSITIVE_NUMBER_ZERO_INCLUSIVE,
  downPayment: commonFields.REQUIRED_POSITIVE_NUMBER_ZERO_INCLUSIVE,
};

export const paymentProgramV2 = {
  remainingPfr: commonFields.REQUIRED_POSITIVE_NUMBER_ZERO_INCLUSIVE,
  balance: commonFields.REQUIRED_POSITIVE_NUMBER_ZERO_INCLUSIVE,
  discount: commonFields.REQUIRED_STRING,
};

export const fullPay = {
  discount: commonFields.REQUIRED_STRING,
};

export const bankSchema = {
  bankName: commonFields.REQUIRED_STRING,
  routingNumber: commonFields.ROUTING_NUMBER,
  accountNumber: commonFields.ACCOUNT_NUMBER,
  retypeAccountNumber: commonFields.RETYPE_ACCOUNT_NUMBER,
};

export const paymentProgramAccordionContents = {
  highRiskItem: commonFields.REQUIRED_STRING,
  downPayment: commonFields.REQUIRED_STRING,
  recurringPayment: commonFields.REQUIRED_STRING,
};

export const PatientMakePayment = {
  makePaymentType: commonFields.REQUIRED_STRING,
  amount: commonFields.REQUIRED_STRING,
};

export const patientDownPaymentCreditCard = {
  payorName: commonFields.REQUIRED_DROPDOWN,
  amount: Yup.number().min(1),
  paymentType: commonFields.REQUIRED_STRING,
  nameOnCard: commonFields.REQUIRED_STRING,
  paymentDay: Yup.string().when("recurringPayment", {
    is: (val: boolean) => val,
    then: commonFields.REQUIRED_STRING,
    otherwise: Yup.string(),
  }),
};

export const patientDownPaymentACH = {
  payorName: commonFields.REQUIRED_DROPDOWN,
  amount: Yup.number().min(1),
  paymentType: commonFields.REQUIRED_STRING,
  ...bankSchema,
  paymentDay: Yup.string().when("recurringPayment", {
    is: (val: boolean) => val,
    then: commonFields.REQUIRED_STRING,
    otherwise: Yup.string(),
  }),
};

export const patientDownPaymentPaidAtFacility = {
  payorName: commonFields.REQUIRED_DROPDOWN,
  amount: Yup.number().min(1),
  paymentType: commonFields.REQUIRED_STRING,
};

export const patientRecurringPayment = {
  payorName: commonFields.REQUIRED_DROPDOWN,
  amount: commonFields.REQUIRED_STRING,
  isPaymentTypeACH: Yup.boolean(),
  nameOnCard: commonFields.REQUIRED_STRING,
  paymentDay: commonFields.REQUIRED_STRING,
};

export const patientRecurringPaymentACH = {
  payorName: commonFields.REQUIRED_DROPDOWN,
  amount: commonFields.REQUIRED_STRING,
  isPaymentTypeACH: Yup.boolean(),
  paymentDay: commonFields.REQUIRED_STRING,
  ...bankSchema,
};

export const patientAddPaymentMethodCard = {
  payorName: commonFields.REQUIRED_DROPDOWN,
  isPaymentTypeACH: Yup.boolean(),
  nameOnCard: commonFields.REQUIRED_STRING,
};

export const patientAddPaymentMethodACH = {
  payorName: commonFields.REQUIRED_DROPDOWN,
  isPaymentTypeACH: Yup.boolean(),
  ...bankSchema,
};

export const facility = {
  facilityName: commonFields.REQUIRED_STRING,
  streetAddress1: commonFields.STREET_ADDRESS_1,
  city: commonFields.CITY,
  state: commonFields.REQUIRED_DROPDOWN,
  zipCode: commonFields.ZIP,
  website: commonFields.WEBSITE,
  phone: commonFields.PHONE,
  primaryContactFirstName: commonFields.FIRST_NAME,
  primaryContactLastName: commonFields.LAST_NAME,
  primaryContactAddress1: commonFields.STREET_ADDRESS_1,
  primaryContactCity: commonFields.CITY,
  primaryContactState: commonFields.REQUIRED_DROPDOWN,
  primaryContactZipCode: commonFields.ZIP,
  primaryContactEmail: commonFields.EMAIL,
  reTypePrimaryContactEmail: Yup.string().oneOf(
    [Yup.ref("primaryContactEmail"), null],
    "Email and Email Confirm must Match"
  ),
  primaryContactPhone: commonFields.PHONE,
  primaryContactMobilePhone: commonFields.PHONE,
  reTypePrimaryContactMobilePhone: Yup.string().oneOf(
    [Yup.ref("primaryContactMobilePhone"), null],
    "Mobile Phone and Mobile Phone Confirm must Match"
  ),
  taxIdNumber: commonFields.TAX_ID,
  // externalAccount: commonFields.REQUIRED_DROPDOWN,
  payOutFreq: commonFields.REQUIRED_STRING,
  payOutDay: Yup.string()
    .when("payOutFreq", {
      is: "weekly",
      then: commonFields.REQUIRED_DROPDOWN,
    })
    .when("payOutFreq", {
      is: "monthly",
      then: commonFields.REQUIRED_DROPDOWN,
    }),
  payNowExternalAccount: Yup.mixed().nullable(),
  bannerText: Yup.string()
    .notRequired()
    .test(
      "bannerText",
      "This field is required",
      makeCustomConfigTest("bannerText")
    ),
  referenceNumberName: Yup.string()
    .notRequired()
    .test(
      "referenceNumberName",
      "This field is required",
      makeCustomConfigTest("referenceNumberName")
    ),
  referenceNumberHelpText: Yup.string()
    .notRequired()
    .test(
      "referenceNumberHelpText",
      "This field is required",
      makeCustomConfigTest("referenceNumberHelpText")
    ),
};

export const completeSetup = {
  ssn: commonFields.SSN,
  birthDate: Yup.date()
    .required()
    .test("age", "Must be 18 or older", function (birthDate) {
      const currentDate = new Date();
      let validation = true;
      currentDate.setFullYear(currentDate.getFullYear() - 18);
      if (birthDate) {
        validation = birthDate <= currentDate;
      }
      return validation;
    }),
  idDocFront: commonFields.REQUIRED_STRING,
  idDocBack: Yup.string().notOneOf(
    [Yup.ref("idDocFront"), null],
    "Cannot be same file as Id Document Front"
  ),
  ...bankSchema,
};

export const balanceAdjustment = (maxAdjustment: number) => {
  return {
    selectChangeType: commonFields.REQUIRED_STRING,
    reasonForChange: commonFields.REQUIRED_DROPDOWN,
    PFRAdjustmentAmt: Yup.number().when("selectChangeType", {
      is: (value: TypeOfChange) => {
        return (
          +value === TypeOfChange["Patient Paid in Full"] ||
          +value === TypeOfChange["Increase PFR by"]
        );
      },
      then: Yup.number(),
      otherwise: Yup.number().max(
        maxAdjustment,
        `You cannot decrease PFR more than the current PFR amount of ${maxAdjustment}`
      ),
    }),
  };
};

export const clientBusinessRules = {
  preCareFullPayMaxDiscount: Yup.number().max(
    10,
    "Pre/During discount is outside acceptable range, please retry"
  ),
  postCareFullPayMaxDiscount: Yup.number().max(
    50,
    "Post discount is outside acceptable range, please retry"
  ),
  refundsIssuedBy: commonFields.REQUIRED_STRING,
  missedPmtsForDefault: commonFields.REQUIRED_STRING,
  pfrVarianceTolerance: commonFields.ONLY_NUMERIC_VALUES,
  automationFee: Yup.number().max(50, "Automation Fee Invalid"),
  merchantProcessingFee: Yup.number().max(
    10,
    "Merchant Processing Fee Invalid"
  ),
  customDocTokenId: commonFields.REQUIRED_STRING,
  realTimeUpdates: Yup.boolean(),
  paynowFee: Yup.number().max(50, "Web Payments Fee Invalid"),
  sendPFRDocs: Yup.boolean(),
};

export const formula = {
  paramName: commonFields.REQUIRED_STRING,
  dataType: commonFields.REQUIRED_DROPDOWN,
  compareBy: commonFields.REQUIRED_DROPDOWN,
};

export const verifyBankModal = {
  firstPayment: Yup.number()
    .typeError("Amount must be a number")
    .min(0.01, "Micro-deposits cannot be less than 1 cent")
    .max(100.0, "Micro-deposit amount is too high"),
  secondPayment: Yup.number()
    .typeError("Amount must be a number")
    .min(0.01, "Micro-deposits cannot be less than 1 cent")
    .max(100.0, "Micro-deposit amount is too high"),
};

export const forwardDocument = {
  email: commonFields.EMAIL,
  retypeEmail: commonFields.RETYPE_EMAIL,
};

export const vobPatientSchema = {
  client: commonFields.REQUIRED_DROPDOWN,
  facility: commonFields.REQUIRED_DROPDOWN,
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().min(2).required("Last Name is required"),
  dob: Yup.date().min('1900-01-01').max(new Date()).nullable().required("Date of Birth is required"),
  payer: Yup.string().required("Payer is required"),
  plan: Yup.string().required("Plan is required"),
};

export const estimatorSchema = {
  client: commonFields.REQUIRED_DROPDOWN,
  facility: commonFields.REQUIRED_DROPDOWN,
  quoteMethod: commonFields.REQUIRED_DROPDOWN,
  serviceLevel: commonFields.REQUIRED_DROPDOWN,
};

export const vobModal = {
  policyBeginDate: Yup.string().required("From Date is required"),
  policyEndDate: Yup.string().required("Until Date is required"),
  policyInNetwork: Yup.boolean(),
  inNetwDeductible: commonFields.IN_NETWORK_INDIVIDUAL_POLICY_ITEM,
  inNetwDeductibleRemaining: commonFields.IN_NETWORK_INDIVIDUAL_POLICY_ITEM,
  inNetwFamilyDeductible: commonFields.IN_NETWORK_FAMILY_POLICY_ITEM,
  inNetwFamilyDeductibleRemaining: commonFields.IN_NETWORK_FAMILY_POLICY_ITEM,
  inNetwOopMax: commonFields.IN_NETWORK_INDIVIDUAL_POLICY_ITEM,
  inNetwOopMaxRemaining: commonFields.IN_NETWORK_INDIVIDUAL_POLICY_ITEM,
  inNetwFamilyOopMax: commonFields.IN_NETWORK_FAMILY_POLICY_ITEM,
  inNetwFamilyOopMaxRemaining: commonFields.IN_NETWORK_FAMILY_POLICY_ITEM,
  inNetwVobClassifications: Yup.array().of(
    Yup.object().shape({
      inNetwork: Yup.boolean(),
      selectedClassification: Yup.object()
        .nullable()
        .optional()
        .when("inNetwork", {
          is: (inNetwork: boolean) => inNetwork,
          then: Yup.object()
            .nullable()
            .required("VOB Classifiction is Required"),
        }),
      coPaySelection: Yup.string()
        .optional()
        .when("inNetwork", {
          is: (inNetwork: boolean) => inNetwork,
          then: Yup.string().required("Copay selection is required"),
        }),
      coInsurance: commonFields.NOT_REQUIRED_NULLABLE_NUMBER.when("inNetwork", {
        is: (inNetwork: boolean) => inNetwork,
        then: commonFields.REQUIRED_NUMBER_VOB.min(0, "Minimum of 0%").max(
          100,
          "Maximum of 100%"
        ),
      }),
      coPay: commonFields.NOT_REQUIRED_NULLABLE_NUMBER.when(
        ["inNetwork", "coPaySelection"],
        {
          is: (inNetwork: boolean, coPaySelection: string) =>
            (coPaySelection === "y" || coPaySelection === "copayonly") &&
            inNetwork,
          then: commonFields.REQUIRED_NUMBER_VOB,
        }
      ),
      maxDays: commonFields.NOT_REQUIRED_NULLABLE_NUMBER.when(
        ["inNetwork", "coPaySelection"],
        {
          is: (inNetwork: boolean, coPaySelection: string) =>
            (coPaySelection === "y" || coPaySelection === "copayonly") &&
            inNetwork,
          then: commonFields.REQUIRED_NUMBER_VOB,
        }
      ),
    })
  ),
  ooNetwDeductible: commonFields.OUT_OF_NETWORK_INDIVIDUAL_POLICY_ITEM,
  ooNetwDeductibleRemaining: commonFields.OUT_OF_NETWORK_INDIVIDUAL_POLICY_ITEM,
  ooNetwFamilyDeductible: commonFields.OUT_OF_NETWORK_FAMILY_POLICY_ITEM,
  ooNetwFamilyDeductibleRemaining:
    commonFields.OUT_OF_NETWORK_FAMILY_POLICY_ITEM,
  ooNetwOopMax: commonFields.OUT_OF_NETWORK_INDIVIDUAL_POLICY_ITEM,
  ooNetwOopMaxRemaining: commonFields.OUT_OF_NETWORK_INDIVIDUAL_POLICY_ITEM,
  ooNetwFamilyOopMax: commonFields.OUT_OF_NETWORK_FAMILY_POLICY_ITEM,
  ooNetwFamilyOopMaxRemaining: commonFields.OUT_OF_NETWORK_FAMILY_POLICY_ITEM,
  ooNetwVobClassifications: Yup.array().of(
    Yup.object().shape({
      inNetwork: Yup.boolean(),
      selectedClassification: Yup.object()
        .nullable()
        .optional()
        .when("inNetwork", {
          is: (inNetwork: boolean) => !inNetwork,
          then: Yup.object()
            .nullable()
            .required("VOB Classifiction is Required"),
        }),
      coPaySelection: Yup.string()
        .optional()
        .when("inNetwork", {
          is: (inNetwork: boolean) => !inNetwork,
          then: Yup.string().required("Copay selection is required"),
        }),
      coInsurance: commonFields.NOT_REQUIRED_NULLABLE_NUMBER.when("inNetwork", {
        is: (inNetwork: boolean) => !inNetwork,
        then: commonFields.REQUIRED_NUMBER_VOB.min(0, "Minimum of 0%").max(
          100,
          "Maximum of 100%"
        ),
      }),
      coPay: commonFields.NOT_REQUIRED_NULLABLE_NUMBER.when(
        ["inNetwork", "coPaySelection"],
        {
          is: (inNetwork: boolean, coPaySelection: string) =>
            (coPaySelection === "y" || coPaySelection === "copayonly") &&
            !inNetwork,
          then: commonFields.REQUIRED_NUMBER_VOB,
        }
      ),
      maxDays: commonFields.NOT_REQUIRED_NULLABLE_NUMBER.when(
        ["inNetwork", "coPaySelection"],
        {
          is: (inNetwork: boolean, coPaySelection: string) =>
            (coPaySelection === "y" || coPaySelection === "copayonly") &&
            !inNetwork,
          then: commonFields.REQUIRED_NUMBER_VOB,
        }
      ),
    })
  ),
  payer: Yup.string(),
  plan: Yup.string(),
};

export const finAssistSchema = {
  streetAddress1: Yup.string().required("Street Address 1 is required"),
  city: Yup.string().required("City is required"),
  stateCode: commonFields.REQUIRED_DROPDOWN,
  zip: Yup.string().min(5).required("Zip Code is required"),
  houseHoldSize: Yup.number().nullable().required("Household Size is Required"),
  monthlyIncome: Yup.number().nullable().required("Monthly Income is Required"),
  monthlyExpenses: Yup.number()
    .nullable()
    .required("Monthly Expenses are Required"),
};

export const completePatientModalSchema = {
  patientTitle: Yup.string().optional(),
  patientFirstName: Yup.string().required("Required"),
  patientLastName: Yup.string().required("Required"),
  email: commonFields.EMAIL.required("Required"),
  retypeEmail: commonFields.RETYPE_EMAIL.required("Required"),
  phone1: Yup.string().required("Required"),
  streetAddress1: Yup.string().optional(),
  city: Yup.string().optional(),
  stateCode: Yup.string().optional(),
  zip: Yup.string()
    .length(5, "Input field must be exactly 5 digits long")
    .matches(/^\d{5}$/, "Input field must contain only numbers")
    .required("Zip Code is required"),
  timingRisk: commonFields.REQUIRED_DROPDOWN,
  payorRisk: commonFields.REQUIRED_DROPDOWN,
  isWarmTransfer: Yup.string().required("Required"),
  isPayNow: Yup.boolean(),
  payNowAmt: Yup.string().when("isPayNow", {
    is: (value: boolean) => value,
    then: Yup.string()
      .matches(
        /^(?!0(?:\.0{1,2})?$)(?:0?\.\d{1,2}|[1-9]\d*(?:\.\d{1,2})?)$/,
        "Invalid input. Only positive numbers with up to 2 decimal points are allowed."
      )
      .required('Amount is required if "Collect Payment Now" is checked'),
  }),
  isPayAtFacility: Yup.boolean(),
  payAtFacilityAmt: Yup.string().when("isPayAtFacility", {
    is: (value: boolean) => value,
    then: Yup.string()
      .matches(
        /^(?!0(?:\.0{1,2})?$)(?:0?\.\d{1,2}|[1-9]\d*(?:\.\d{1,2})?)$/,
        "Invalid input. Only positive numbers with up to 2 decimal points are allowed."
      )
      .required(
        'Amount is required if "Collect Payment at Facility" is checked'
      ),
  }),
  nameOnCard: Yup.string().when("isPayNow", {
    is: (isPayNow: boolean) => isPayNow,
    then: Yup.string().required(
      "Card Name is required if a collect payment box is checked"
    ),
  }),
};

export const saveEstimateModalSchema = {
  estimateDescription: Yup.string().required("Required"),
};

export const riskScoringSchema = {
  facility: commonFields.REQUIRED_DROPDOWN,
  facilityCensus: commonFields.REQUIRED_NUMBER_VOB.transform((value) => (isNaN(value) ? undefined : value)).min(1, "Minimum of 1%").max(
    100,
    "Maximum of 100%"
  ),
  payerRisk: commonFields.REQUIRED_DROPDOWN,
  timingRisk: commonFields.REQUIRED_DROPDOWN,
  riskClass: Yup.number().required().min(1, ""),
  referralSource: Yup.number().when('$hasReferralSourcesConfigured', {
    is: true,
    then: Yup.number().required("Missing Referral Source Selection").min(1),
    otherwise: Yup.number().notRequired()
  }) 
};

export const newRiskScoringSchema = {
  facility: commonFields.REQUIRED_DROPDOWN,
  facilityCensus: commonFields.REQUIRED_NUMBER_VOB.transform((value) => (isNaN(value) ? undefined : value)).min(1, "Minimum of 1%").max(
    100,
    "Maximum of 100%"
  ),
  payerRiskId: commonFields.REQUIRED_DROPDOWN,
  timingRiskId: commonFields.REQUIRED_DROPDOWN,
  riskClassId: Yup.number().required().min(1, ""),
  facilityReferralSourceId: Yup.number().when('$hasReferralSourcesConfigured', {
    is: true,
    then: Yup.number().required("Missing Referral Source Selection").min(1),
    otherwise: Yup.number().notRequired()
  }) 
};
